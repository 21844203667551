const isEmpty = function (val) {
  if (val === null || typeof val === 'undefined' || val === '')
    return true;
  else
    return false;
};

// Boolの比較関数
const compareBool = function (condition, prop) {
  if (!isEmpty(condition)) {
    return condition === prop;
  }
  else {
    return true;
  }
};

// Numberの比較関数
const compareNumber = function (condition, prop) {
  if (!isEmpty(condition)) {
    return condition === prop;
  }
  else {
    if (condition === 0) {
      return condition === prop;
    }
    return true;
  }
};

// 文字列の比較関数
export const compareString = function (condition, prop, isMatched) {
  const con = (typeof condition === 'number') ? String(condition) : condition;
  if (!isEmpty(con)) {
    // 検索条件は指定されているがプロパティの値が空
    if (!prop) {
      return false;
    }
    // 完全一致判定
    if (isMatched) {
      return con.toLowerCase() === prop.toLowerCase();
    }
    else {
      // 部分一致判定
      return prop.toLowerCase().indexOf(con.toLowerCase()) >= 0;
    }
  } else {
    return true;  // 検索条件が指定されていない
  }
};

// 時刻の比較関数
export const compareDate = function (rangeStart, rangeEnd, date) {
  const targetDate = date ? date : 0;
  if (!rangeStart || !rangeEnd) {
    // 検索条件に期間が指定されていない
    return true;
  }
  else {
    // 比較
    if (targetDate >= rangeStart && targetDate < rangeEnd) {
      return true;
    }
    else {
      return false;
    }
  }
};

export default {
  compareBool,
  compareNumber,
  compareString,
  compareDate
};