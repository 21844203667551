/* eslint-disable max-lines */

const Constants = require('@/common/nodejs/constants');

// Front Side Constant class

export default Object.freeze({

  // Domain Type
  SBR_DOMAIN: 'sbr',
  SCI_DOMAIN: 'sci',
  SOA_DOMAIN: 'soa',
  THIRD_PARTY_DOMAIN: 'third_party',

  // product environment 
  MULTI_DOMAIN_MAP: {
    'ssm.subaru.co.jp': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'ca.ssm.subaru.co.jp': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'ssm5.subaru.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber.ssm5.subaru.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'd15va41apjrm5c.cloudfront.net': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // TEST environment 
  TEST_MULTI_DOMAIN_MAP: {
    'test1.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'test1.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-test1.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-test1.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  TEST2_MULTI_DOMAIN_MAP: {
    'test2.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'test2.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-test2.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-test2.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  TEST3_MULTI_DOMAIN_MAP: {
    'test3.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'test3.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-test3.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-test.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // dev1 environment 
  DEV_MULTI_DOMAIN_MAP: {
    'dev.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // dev2 environment 
  DEV2_MULTI_DOMAIN_MAP: {
    'dev2.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev2.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev.sbr-dev2.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev2.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // dev3 environment 
  DEV3_MULTI_DOMAIN_MAP: {
    'dev3.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev3.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev3.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev3.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  // dev4 environment 
  DEV4_MULTI_DOMAIN_MAP: {
    'dev4.ssm-dev.com': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'dev4.ca.ssm-dev.com': {
      type: 'sci',
      labelLoginTop: 'message.label_signin_other_system_sci',
      labelLoginBottom: 'message.label_signin_other_system_under_sci',
      provider: 'SCI'
    },
    'soa-dev4.sbr-dev.com': {
      type: 'soa',
      labelLoginTop: 'message.label_signin_other_system_soa',
      provider: 'SUBARUNET'
    },
    'subscriber-dev4.ssm.sbr-dev.com': {
      type: 'third_party',
      labelLoginTop: 'message.label_signin_other_system_third_party',
      labelLoginBottom: 'message.label_signin_other_system_under_third_party',
      provider: 'SUBSCRIBER'
    },
    'd1r0yee6nzclc8.cloudfront.net': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
    'localhost': {
      type: 'sbr',
      labelLoginTop: 'message.label_signin_other_system',
      labelLoginBottom: 'message.label_signin_other_system_under',
      provider: 'SUBARU'
    },
  },

  /** APIの実行先(ADMIN) @deprecated 将来削除予定。今後は share\src\util\api\service.js を利用すること。 */
  ADMIN: 'admin',
  /** APIの実行先(REPRO) @deprecated 将来削除予定。今後は share\src\util\api\service.js を利用すること。 */
  REPRO: 'repro',
  /** APIの実行先(MASTER) @deprecated 将来削除予定。今後は share\src\util\api\service.js を利用すること。 */
  MASTER: 'master',
  /** APIの実行先(MAINT) @deprecated 将来削除予定。今後は share\src\util\api\service.js を利用すること。 */
  MAINT: 'maintenance',
  /** APIの実行先(GPLM) @deprecated 将来削除予定。今後は share\src\util\api\service.js を利用すること。 */
  GPLM: 'gplm',

  // 対応言語の一覧(表示文言は各言語で表示するため固定)
  LANGUAGE_LIST: [
    { value: 'ja', label: '日本語' },
    { value: 'en', label: 'English' },
    { value: 'zh', label: '中文' },
    { value: 'it', label: 'Italiano' },
    { value: 'es', label: 'Español' },
    { value: 'fr', label: 'Français' },
    { value: 'de', label: 'Deutsch' },
    { value: 'ru', label: 'Русский' }
  ],

  // ユーザーに紐づくキャッシュ情報のキー
  USER_CACHE_PFC_SEARCH_KEY_APPLOVAL: 'approvalSearchKey',
  USER_CACHE_REPRO_SYSTEM_TRANSLATION: 'reproSystemTranslation',
  USER_CACHE_REPRO_FAILUER_INFO: 'reproFailureInformation',
  USER_CACHE_REPRO_CHASSIS_AREA_NAME: 'reproChassisAreaName',
  USER_CACHE_REPRO_TARGET_INFO: 'reproTargetInformation',

  // ナビゲーションバーの表示種別
  // ナビゲーションバー 表示なし
  NAVIGATION_NONE: 'none',
  // ナビゲーションバー 表示あり
  NAVIGATION_DISPLAY: 'display',
  // ナビゲーションバー無効(disabled)
  NAVIGATION_DISABLED: 'disabled',

  // 所属の判別
  ASSIGNED_COMPANY: Constants.USER_TYPE_COMPANY,
  ASSIGNED_SHOP: Constants.USER_TYPE_SHOP,

  // 会社種別
  SBR_COMPANY: 'sbr_company',
  SOA_COMPANY: 'soa_company',
  SCI_COMPANY: 'sci_company',
  NASI_COMPANY: 'nasi_company',

  // リージョン情報
  REGION_DOMESTIC: Constants.COUNTRY_GROUP_DOMESTIC,
  REGION_NORTH_AMERICA: Constants.COUNTRY_GROUP_NORTH_AMERICA,
  REGION_EUROPE: Constants.COUNTRY_GROUP_EUROPE,

  // 権限グループ一覧
  GROUP_SYSTEM: Constants.USER_GROUP_SYSTEM_ADMIN,
  GROUP_SERVICE: Constants.USER_GROUP_SERVICE_ADMIN,
  GROUP_PFC: Constants.USER_GROUP_REPRO_REGISTRANT,
  GROUP_GENERAL: Constants.USER_GROUP_USERS,
  GROUP_REPRO_FILE_ADMIN: Constants.USER_GROUP_REPRO_FILE_ADMIN,
  GROUP_REPRO_FILE_TESTER: Constants.USER_GROUP_REPRO_FILE_TESTER,
  GROUP_USER_ADMIN: Constants.USER_GROUP_USER_ADMIN,
  GROUP_SBR_REPRO_TESTER: Constants.USER_GROUP_SBR_REPRO_TESTER,

  // 利用者の検索条件（廃止区分）
  SEARCH_USER_ACTIVE: 'active',
  SEARCH_USER_ABOLISHED_ALL: 'all',
  SEARCH_USER_ABOLISHED_MANUAL: 'manual',
  SEARCH_USER_ABOLISHED_AUTO: 'auto',

  // サービス名一覧
  SERVICE_REPRO: Constants.SERVICE_NAME_REPRO,
  SERVICE_MANAGE: Constants.SERVICE_NAME_MANAGE,

  // バリデーション条件
  SSM5_INVALID_CHAR_PATTERN: /^(?!.*(¥|\\|")).*$/,
  USER_ID_MAX_LEN: Constants.USER_ID_MAXIMUM_LENGTH,
  USER_EMAIL_MAX_LEN: Constants.USER_EMAIL_MAXIMUM_LENGTH,
  USER_FIRSTNAME_MAX_LEN: Constants.USER_FIRSTNAME_MAXIMUM_LENGTH,
  USER_LASTNAME_MAX_LEN: Constants.USER_LASTNAME_MAXIMUM_LENGTH,
  USER_INFO_MAX_LEN_64: Constants.USER_INFO_MAXIMUM_LENGTH_64,
  USER_INFO_MAX_LEN_256: Constants.USER_INFO_MAXIMUM_LENGTH_256,
  USER_PASSWORD_MIN_LEN: Constants.USER_PASSWORD_MINIMUM_LENGTH,
  USER_PASSWORD_MAX_LEN: Constants.USER_PASSWORD_MAXIMUM_LENGTH,
  COMPANYNAME_MAX_LEN: Constants.COMPANYNAME_MAXIMUM_LENGTH,
  DEPARTMENTNAME_MAX_LEN: Constants.DEPARTMENTNAME_MAXIMUM_LENGTH,
  EMAIL_PATTERN: Constants.EMAIL_PATTERN_REGX,
  URL_PATTERN: Constants.URL_PATTERN_REGX,
  COMPANY_CODE_PATTERN: Constants.COMPANY_CODE_PATTERN,
  SHOP_CODE_PATTERN: Constants.SHOP_CODE_PATTERN,
  SSM_COUNTRY_CODE_PATTERN: Constants.SSM_COUNTRY_CODE_PATTERN,
  TARGETUNIT_SYSTEMNAME_PATTERN: Constants.TARGETUNIT_SYSTEMNAME_PATTERN,
  VEHICLEINFO_CODE_PATTERN: Constants.VEHICLEINFO_CODE_PATTERN,
  VEHICLEINFO_MODEL_PATTERN: Constants.VEHICLEINFO_MODEL_PATTERN,
  VEHICLEINFO_ANNUAL_IMPROVEMENTS_PATTERN: Constants.VEHICLEINFO_ANNUAL_IMPROVEMENTS_PATTERN,
  VEHICLEINFO_ANNUAL_CLASSIFICATION_PATTERN: Constants.VEHICLEINFO_ANNUAL_CLASSIFICATION_PATTERN,
  VEHICLEINFO_MODEL_YEAR_PATTERN: Constants.VEHICLEINFO_MODEL_YEAR_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER1_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER1_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER1_A_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER1_A_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER2_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER2_PATTERN,
  VEHICLEINFO_CHASSIS_NUMBER2_A_PATTERN: Constants.VEHICLEINFO_CHASSIS_NUMBER2_A_PATTERN,
  VEHICLEINFO_APPLIED_MODEL_PATTERN: Constants.VEHICLEINFO_APPLIED_MODEL_PATTERN,
  VEHICLEINFO_APPLIED_MODEL_A_PATTERN: Constants.VEHICLEINFO_APPLIED_MODEL_A_PATTERN,
  VEHICLEINFO_VIN_PATTERN: Constants.VEHICLEINFO_VIN_PATTERN,
  VEHICLEINFO_VIN_A_PATTERN: Constants.VEHICLEINFO_VIN_A_PATTERN,
  VEHICLEINFO_PUB_NO_PATTERN: Constants.VEHICLEINFO_PUB_NO_PATTERN,
  VEHICLEINFO_VEHICLE_MODEL_PATTERN: /^[a-zA-Z0-9 +]*$/,
  CANIDINFO_CANID_PATTERN: Constants.CANIDINFO_CANID_PATTERN,

  PFC_CVN_MAX_LEN: Constants.PFC_CVN_MAX_LEN,
  PFC_ENCRYPTION_MAX_LEN: Constants.PFC_ENCRYPTION_MAX_LEN,
  PFC_SERVICE_NEWS_MAX_LEN: Constants.PFC_SERVICE_NEWS_MAX_LEN,
  PFC_SUPPLEMENTARY_MAX_LEN: Constants.PFC_SUPPLEMENTARY_MAX_LEN,
  PFC_REMARKS_MAX_LEN: Constants.PFC_REMARKS_MAX_LEN,
  PFC_DETAILS_MAX_LEN: Constants.PFC_DETAILS_MAX_LEN,
  PFC_NOTES_MAX_LEN: Constants.PFC_NOTES_MAX_LEN,
  PFC_DEALER_SERVICE_NEWS_MAX_LEN: Constants.PFC_DEALER_SERVICE_NEWS_MAX_LEN,
  PFC_CVN_PATTERN: Constants.PFC_CVN_PATTERN,
  // 現在使用していないためコメントアウトする
  // PFC_ENCRYPTION_PATTERN: Constants.PFC_ENCRYPTION_PATTERN,
  PFC_SERVICE_NEWS_PATTERN: Constants.PFC_SERVICE_NEWS_PATTERN,
  PFC_DEALER_SERVICE_NEWS_PATTERN: Constants.PFC_DEALER_SERVICE_NEWS_PATTERN,

  // 会社区分のグループ
  COMPANY_GROUP_DISTRIBUTOR: Constants.COMPANY_GROUP_DISTRIBUTOR,
  COMPANY_GROUP_TRADING: Constants.COMPANY_GROUP_TRADING_COMPANY,
  COMPANY_GROUP_GENERAL: Constants.COMPANY_GROUP_MAINTENACE_COMPANY,
  COMPANY_GROUP_SBR: Constants.COMPANY_GROUP_SBR,
  COMPANY_GROUP_AREA: Constants.COMPANY_GROUP_AREA,
  COMPANY_GROUP_OTHER: Constants.COMPANY_GROUP_OTHER,
  COMPANY_GROUP_REGION: Constants.COMPANY_GROUP_REGION,

  COMPANY_DIRECT: Constants.COMPANY_DIRECT,
  COMPANY_INDIRECT: Constants.COMPANY_INDIRECT,
  COMPANY_HYPHEN: Constants.COMPANY_HYPHEN,

  COMPANY_ID_MAX_LEN: Constants.COMPANY_ID_MAX_LEN,
  COMPANY_NAME_MAX_LEN: Constants.COMPANY_NAME_MAX_LEN,
  COMPANY_ADDRESS_MAX_LEN: Constants.COMPANY_ADDRESS_MAX_LEN,
  COMPANY_BULLETINURL_MAX_LEN: Constants.COMPANY_BULLETINURL_MAX_LEN,
  COMPANY_REMARKS_MAX_LEN: Constants.COMPANY_REMARKS_MAX_LEN,

  // 国内の店舗区分
  SHOP_GROUP_JP_HEAD_OFFICE: Constants.SHOP_GROUP_JP_HEAD_OFFICE,
  SHOP_GROUP_JP_DEALER: Constants.SHOP_GROUP_JP_DEALER,
  SHOP_GROUP_JP_TRADE_SALES: Constants.SHOP_GROUP_JP_TRADE_SALES,
  SHOP_GROUP_JP_EXCEPT_DISTRIBUTOR: Constants.SHOP_GROUP_JP_EXCEPT_DISTRIBUTOR,
  // 北米の店舗区分
  SHOP_GROUP_NA_DIST_HQ: Constants.SHOP_GROUP_NA_DIST_HQ,
  SHOP_GROUP_NA_DEALER: Constants.SHOP_GROUP_NA_DEALER,
  SHOP_GROUP_NA_AFTERMARKET_SHOP: Constants.SHOP_GROUP_NA_AFTERMARKET_SHOP,
  SHOP_GROUP_NA_DIST_BRANCH: Constants.SHOP_GROUP_NA_DIST_BRANCH,
  SHOP_GROUP_NA_SERVICE_SHOP: Constants.SHOP_GROUP_NA_SERVICE_SHOP,
  SHOP_GROUP_NA_GST: Constants.SHOP_GROUP_NA_GST,
  SHOP_GROUP_NA_OTHER: Constants.SHOP_GROUP_NA_OTHER,
  // 欧州一般の店舗区分
  SHOP_GROUP_EU_DIST_HQ: Constants.SHOP_GROUP_EU_DIST_HQ,
  SHOP_GROUP_EU_DIST_BRANCH: Constants.SHOP_GROUP_EU_DIST_BRANCH,
  SHOP_GROUP_EU_DIST_INDEPENDENT: Constants.SHOP_GROUP_EU_DIST_INDEPENDENT,
  SHOP_GROUP_EU_DEALER: Constants.SHOP_GROUP_EU_DEALER,
  SHOP_GROUP_EU_SERVICE_SHOP: Constants.SHOP_GROUP_EU_SERVICE_SHOP,
  SHOP_GROUP_EU_OTHER: Constants.SHOP_GROUP_EU_OTHER,
  // その他の店舗区分
  SHOP_GROUP_OTHER_SERVICE_DEPERTMENT: Constants.SHOP_GROUP_OTHER_SERVICE_DEPERTMENT,
  SHOP_GROUP_OTHER_ENGINEERING_DEPERTMENT: Constants.SHOP_GROUP_OTHER_ENGINEERING_DEPERTMENT,
  SHOP_GROUP_OTHER_OTHER: Constants.SHOP_GROUP_OTHER_OTHER,

  // 型式と年改、VINとPubNoを分けるデリミタ
  VEHICLEINFO_DELIMITER: Constants.VEHICLEINFO_DELIMITER,
  // 車両番号を分けるデリミタ
  VEHICLEINFO_VEHICLENUMBER_DELIMITER: Constants.VEHICLEINFO_VEHICLENUMBER_DELIMITER,

  SHOP_NAME_MAX_LEN: Constants.SHOP_NAME_MAX_LEN,
  SHOP_MANAGEMENT_CODE_MAX_LEN: Constants.SHOP_MANAGEMENT_CODE_MAX_LEN,
  SHOP_ADDRESS_MAX_LEN: Constants.SHOP_ADDRESS_MAX_LEN,
  SHOP_REMARKS_MAX_LEN: Constants.SHOP_REMARKS_MAX_LEN,
  COUNTRY_SSMCODE_MAX_LEN: Constants.COUNTRY_SSMCODE_MAX_LEN,
  COUNTRY_NAMEJA_MAX_LEN: Constants.COUNTRY_NAMEJA_MAX_LEN,
  COUNTRY_NAMEEN_MAX_LEN: Constants.COUNTRY_NAMEEN_MAX_LEN,
  COUNTRY_ABBREVIATION_MAX_LEN: Constants.COUNTRY_ABBREVIATION_MAX_LEN,
  TARGETUNIT_SYSTEMNAME_MAX_LEN: Constants.TARGETUNIT_SYSTEMNAME_MAX_LEN,
  TARGETUNIT_NAME_MAX_LEN: Constants.TARGETUNIT_NAME_MAX_LEN,
  ENGINE_NAME_MAX_LEN: Constants.ENGINE_NAME_MAX_LEN,
  INTAKE_NAME_MAX_LEN: Constants.INTAKE_NAME_MAX_LEN,
  TM_NAME_MAX_LEN: Constants.TM_NAME_MAX_LEN,
  REPRO_NAME_MAX_LEN: Constants.REPRO_NAME_MAX_LEN,
  VEHICLEINFO_CODE_MAX_LEN: Constants.VEHICLEINFO_CODE_MAX_LEN,
  VEHICLEINFO_VEHICLE_MODEL_MAX_LEN: Constants.VEHICLEINFO_VEHICLE_MODEL_MAX_LEN,
  VEHICLEINFO_MODEL_MAX_LEN: Constants.VEHICLEINFO_MODEL_MAX_LEN,
  VEHICLEINFO_ANNUAL_IMPROVEMENTS_MAX_LEN: Constants.VEHICLEINFO_ANNUAL_IMPROVEMENTS_MAX_LEN,
  VEHICLEINFO_MODEL_YEAR_MAX_LEN: Constants.VEHICLEINFO_MODEL_YEAR_MAX_LEN,
  VEHICLEINFO_CHASSIS_NUMBER1_MAX_LEN: Constants.VEHICLEINFO_CHASSIS_NUMBER1_MAX_LEN,
  VEHICLEINFO_CHASSIS_NUMBER2_MAX_LEN: Constants.VEHICLEINFO_CHASSIS_NUMBER2_MAX_LEN,
  VEHICLEINFO_APPLIED_MODEL_MAX_LEN: Constants.VEHICLEINFO_APPLIED_MODEL_MAX_LEN,
  VEHICLEINFO_VIN_MAX_LEN: Constants.VEHICLEINFO_VIN_MAX_LEN,
  VEHICLEINFO_PUB_NO_MAX_LEN: Constants.VEHICLEINFO_PUB_NO_MAX_LEN,
  VEHICLEINFO_ANNUAL_CLASSIFICATION_MAX_LEN: Constants.VEHICLEINFO_ANNUAL_CLASSIFICATION_MAX_LEN,
  CANIDINFO_CODE_MAX_LEN: Constants.CANIDINFO_CODE_MAX_LEN,
  CANIDINFO_NAME_MAX_LEN: Constants.CANIDINFO_NAME_MAX_LEN,
  CANIDINFO_CANID_MAX_LEN: Constants.CANIDINFO_CANID_MAX_LEN,
  CANIDINFO_FUNC_MAX_LEN: Constants.CANIDINFO_FUNC_MAX_LEN,

  // 車種情報のtypeフィールドの値
  VEHICLEINFO_TYPE_UN_KNOWN: 'unknown',
  VEHICLEINFO_TYPE_DEVELOPMENTCODE: Constants.VEHICLEINFO_TYPE_DEVELOPMENTCODE,
  VEHICLEINFO_TYPE_IMPROVEMENTSCODE: Constants.VEHICLEINFO_TYPE_IMPROVEMENTS_CODE,
  VEHICLEINFO_TYPE_AREA: Constants.VEHICLEINFO_TYPE_AREA,
  VEHICLEINFO_TYPE_VEHICLEMODEL: Constants.VEHICLEINFO_TYPE_VEHICLEMODEL,
  VEHICLEINFO_TYPE_CLASSIFICATION: Constants.VEHICLEINFO_TYPE_CLASSIFICATION,
  VEHICLEINFO_TYPE_MODELYEAR: Constants.VEHICLEINFO_TYPE_MODELYEAR,
  VEHICLEINFO_TYPE_CHASSISNUMBER: Constants.VEHICLEINFO_TYPE_CHASSISNUMBER,
  VEHICLEINFO_TYPE_VIN: Constants.VEHICLEINFO_TYPE_VIN,

  // PFC一覧
  PFC_STATUS_TEMP_REGISTERED: Constants.PFC_PUBLISH_STATUS_UNPUBLISHED,
  PFC_STATUS_RELEASED: Constants.PFC_PUBLISH_STATUS_PUBLISHED,
  PFC_STATUS_NOT_RELEASED: Constants.PFC_PUBLISH_STATUS_DISCONTINUED,
  PFC_STATUS_APPROVALED: Constants.PFC_APPROVAL_STATUS_APPROVED,
  PFC_STATUS_NOT_APPROVALED: Constants.PFC_APPROVAL_STATUS_UNAPPROVED,
  PFC_STATUS_DISTRIBUTOR_ONLY: Constants.PFC_APPROVAL_STATUS_DISTRIBUTOR_ONLY,

  // 車両通信フェーズ(Frontend)
  COMMUNICATION_UI_VIN_REQUIRED: "vin_required",
  COMMUNICATION_UI_VIN_COMPLETED: "vin_completed",
  COMMUNICATION_UI_VEHICLE_CGW: "communication_cgw",
  COMMUNICATION_UI_VEHICLE_OBD: "communication_obd",
  COMMUNICATION_UI_VEHICLE_SYSTEMS: "communication_systems",
  COMMUNICATION_UI_RXSWIN_UPDATED: "rxswin_updated",
  COMMUNICATION_UI_GET_DIAG_CODE: "get_diagcode",
  COMMUNICATION_UI_COMMUNICATION_COMPLETED: "communication_completed",

  // 車両通信フェーズ(Backend)
  COMMUNICATION_API_READ_VBAT: Constants.VEHICLE_INFO_CHECK_STATUS_READ_BATTERY,
  COMMUNICATION_API_CHECK_CGW_SUPPORT: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_CGW,
  COMMUNICATION_API_READ_INSTALLED_ECU: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_INSTALLED_ECU,
  COMMUNICATION_API_READ_COMMUNICATED_ECU: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_COMMUNICATED_ECU,
  COMMUNICATION_API_READ_VIN: Constants.VEHICLE_INFO_CHECK_STATUS_READ_VIN,
  COMMUNICATION_API_READ_SECURITY_ACCESS: Constants.VEHICLE_INFO_CHECK_STATUS_READ_SECURITY_ACCESS,
  COMMUNICATION_API_ALL_TARGET_INQUIRY: Constants.VEHICLE_INFO_CHECK_STATUS_ALL_TARGET_INQUIRY,
  COMMUNICATION_API_COMPLETED: Constants.VEHICLE_INFO_CHECK_STATUS_COMPLETED,
  COMMUNICATION_API_READ_RxSWIN: Constants.VEHICLE_INFO_CHECK_STATUS_READ_RxSWIN,
  COMMUNICATION_API_UPDATING_RxSWIN: Constants.VEHICLE_INFO_CHECK_STATUS_RXSWIN_UPDATING,
  COMMUNICATION_API_CHASSIS_COMPLETED: Constants.VEHICLE_INFO_VEHICLE_CHECK_STATUS_CHASSIS_COMPLETED,
  COMMUNICATION_API_VIN_COMPLETED: Constants.VEHICLE_INFO_VEHICLE_CHECK_STATUS_VIN_COMPLETED,
  COMMUNICATION_API_VIN_FAILED: Constants.VEHICLE_INFO_VEHICLE_CHECK_STATUS_FAILED,
  COMMUNICATION_API_DIAG_INFO: Constants.VEHICLE_INFO_CHECK_STATUS_DIAG_INFO,
  COMMUNICATION_API_READ_VEHICLE_TIMESTAMP: Constants.VEHICLE_INFO_CHECK_STATUS_READ_TIMESTAMP,
  COMMUNICATION_API_INSTALL_ECU: Constants.VEHICLE_INFO_CHECK_STATUS_CHECK_INSTALLED_COMMUNICATED_ECU,

  // 車両情報
  SYSTEM_STATUS_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_REPROGRAMMABLE,
  SYSTEM_STATUS_NOT_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_NOT_REPROGRAMMABLE,
  SYSTEM_STATUS_INSTALLED: Constants.INSTALL_STATUS_INSTALLED,
  SYSTEM_STATUS_NOT_INSTALLED: Constants.INSTALL_STATUS_NOT_INSTALLED,
  SYSTEM_STATUS_COMMUNICATED: Constants.COMMUNICATE_STATUS_COMMUNICATED,
  SYSTEM_STATUS_NOT_COMMUNICATED: Constants.COMMUNICATE_STATUS_NOT_COMMUNICATED,

  // ID Type
  TARGET_ID_TYPE_ROMID: Constants.TARGET_ID_TYPE_ROMID,
  TARGET_ID_TYPE_CID: Constants.TARGET_ID_TYPE_CID,
  TARGET_ID_TYPE_SWID: Constants.TARGET_ID_TYPE_SWID,

  // リプロ実行可能判定
  REPRO_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_REPROGRAMMABLE,
  REPRO_NOT_REPROGRAMMABLE: Constants.PFC_REPROGRAM_STATUS_NOT_REPROGRAMMABLE,
  REPRO_REPROGRAMMABLE_ERROR: Constants.PFC_REPROGRAM_STATUS_ERROR,
  REPRO_INSTALLED: Constants.INSTALL_STATUS_INSTALLED,
  REPRO_NOT_INSTALLED: Constants.INSTALL_STATUS_NOT_INSTALLED,
  REPRO_INSTALLED_ERROR: Constants.INSTALL_STATUS_ERROR,
  REPRO_COMUNICATED: Constants.COMMUNICATE_STATUS_COMMUNICATED,
  REPRO_NOT_COMUNICATED: Constants.COMMUNICATE_STATUS_NOT_COMMUNICATED,
  REPRO_COMUNICATED_ERROR: Constants.COMMUNICATE_STATUS_ERROR,
  STATUS_RPR_ENABLED: "enabled",
  STATUS_RPR_DISABLED: "disabled",
  STATUS_UPDATE_CONSENT: Constants.REPRO_CONSENT_STATUS_CONSENTED,
  STATUS_UPDATE_REJECT: Constants.REPRO_CONSENT_STATUS_REJECTED,
  STATUS_CONSENT_UNSETTED: Constants.REPRO_CONSENT_STATUS_UNSETTED,

  // Repro programming states
  REPRO_FILE_DOWNLOADING: Constants.REPRO_FILE_DOWNLOADING,
  REPRO_PRE_PROGRAMMING: Constants.REPRO_PRE_PROGRAMMING,
  REPRO_PROGRAMMING: Constants.REPRO_PROGRAMMING,
  REPRO_POST_PROGRAMMING: Constants.REPRO_POST_PROGRAMMING,

  // ポーリング状況
  POLLING_INITIALIZED: "init",
  POLLING_RUNNING: "run",
  POLLING_COMPLETED: "complete",
  POLLING_FAILURE: "failure",

  // EXECUTE 画面での処理種別
  PROCESS_IDLING: "idling",
  PROCESS_REPRO_PROCESSING: "processing",
  PROCESS_REPRO_COMPLETED: "completed",
  PROCESS_REPRO_SUB_FAILED: "usb_failed",
  // EXECUTE 画面でのコンテナ種別
  CONTAINER_VEHICLE: "vehicle",
  CONTAINER_ATTENTION: "attention",
  CONTAINER_EXECUTING: "executing",
  CONTAINER_CHECK_IG_OFF: "ig-off",
  CONTAINER_CHECK_IG_ON: "ig-on",
  CONTAINER_IG_COUNT_DOWN: "ig-count-down",
  CONTAINER_CCU_REPROGRAMMING: "ccu-reprogramming",
  CONTAINER_CONFIRM_COMPLETE_CCU_REPROGRAMMING: "confirm-complete-ccu-reprogramming",
  CONTAINER_IS_LONG_INTERVAL: "is-long-interval",
  
  // リプロ実行結果
  STATUS_RPR_EXECUTING_ERROR: "executing-error",
  STATUS_RPR_FINISHED_ERROR: "finished-error",
  STATUS_RPR_FINISHED_SUCCESS: "finished-success",

  // ソートのソート順定義
  ASC: "asc",
  DESC: "desc",

  // List item specification
  LIST_ITEM_HIGHLIGHT: "highlight",
  LIST_ITEM_ERROR: "error",
  LIST_ITEM_BOLD: 'bold',
  LIST_ITEM_DISABLE: 'disable',

  // 会社ツリー更新用の定義
  TREE_UPDATE_TPYE_NONE: "none",
  TREE_UPDATE_TPYE_ALL: "all",
  TREE_UPDATE_TPYE_ID: "id",
  TREE_UPDATE_TPYE_UPDATE: "update",
  TREE_UPDATE_TPYE_DELETE: "delete",

  // 共通のハイフン
  COMMON_HYPHEN_VALUE: '-',
  // バージョン未取得時の定義
  VERSION_UNKNOWN: Constants.VERSION_UNKNOWN,
  // バージョン未取得時の表示
  VERSION_UNKNOWN_DISPLAY: "-.-.-",

  PFC_FILE_CONFIGURATION: [
    'engine_id', 'aspiration_id', 'transmission_id', 'repro_factor_id', 'is_connected',
    'short_connector', 'cvn', 'service_news', 'supplementary_en', 'supplementary_ja',
    'remarks', 'details_en', 'details_ja', 'notes_en', 'notes_ja'
  ],

  PFC_HISTORY_CONFIGURATION: [],

  SERVICE_NEWS_MAX_COUNT: 25,
  WAIT_DB_EVENT_TIME: 1500,

  // PFC file and unit list types
  PFC_FILE_LIST_TYPE_OWN_COUNTRY: Constants.PFC_FILE_LIST_TYPE_OWN_COUNTRY,
  PFC_FILE_LIST_TYPE_OTHER_COUNTRY: Constants.PFC_FILE_LIST_TYPE_OTHER_COUNTRY,

  // G-PLM 連携
  GPLM_DL_REPRO_FILE: Constants.DOWNLOAD_REPROGRAMMING_FILES,
  GPLM_DL_CONFIG_INFO: Constants.DOWNLOAD_CONFIGURATION_INFORMATION,

  // PFC file type
  PFC_FILE_TYPE_DEFAULT: 'pfc',
  PFC_FILE_TYPE_CCU: 'ccu',

  // PFC file 登録方法
  PFC_FILE_REGISTERD_BY_GPLM: 'gplm',
  PFC_FILE_REGISTERD_BY_MANUAL: 'manual',

  DTC_STATUS_TENTATIVE: Constants.DTC_STATUS_TENTATIVE,
  DTC_STATUS_CURRENT: Constants.DTC_STATUS_CURRENT,
  DTC_STATUS_PAST: Constants.DTC_STATUS_PAST,
  DTC_STATUS_CONFIRM: Constants.DTC_STATUS_CONFIRM,
  DTC_STATUS_LATEST: Constants.DTC_STATUS_LATEST,
  DTC_STATUS_CONFIRM_LATEST: Constants.DTC_STATUS_CONFIRM_LATEST,
  DTC_STATUS_CONFIRM_TENTATIVE: Constants.DTC_STATUS_CONFIRM_TENTATIVE,
  DTC_STATUS_TENTATIVE_LATEST: Constants.DTC_STATUS_TENTATIVE_LATEST,
  DTC_STATUS_CONFIRM_TENTATIVE_LATEST: Constants.DTC_STATUS_CONFIRM_TENTATIVE_LATEST,
  DTC_STATUS_NO_DTC: Constants.DTC_STATUS_NO_DTC,
  DTC_STATUS_DTC_NOT_SUPPORT: Constants.DTC_STATUS_NOT_SUPPORT,
  DTC_STATUS_CANNNOT_CONNECT: Constants.DTC_STATUS_CANNOT_CONNECT,

  // 実行回数
  USAGE_COUNT_REPRO: Constants.USAGE_COUNT_TYPE_REPRO,
  USAGE_COUNT_DIAG: Constants.USAGE_COUNT_TYPE_DIAG,

  // 車両情報取得種別 
  // ALL : リプロ・診断
  INQUIRY_TYPE_ALL: Constants.VEHICLE_INFO_INQUIRY_TYPE_ALL,
  // DTC : 診断 のみ
  INQUIRY_TYPE_DTC: Constants.VEHICLE_INFO_INQUIRY_TYPE_DTC,

  // Protocol名
  PROTOCOL_FHI_CAN: Constants.PROTOCOL_FHI_CAN,
  PROTOCOL_PHASE4: Constants.PROTOCOL_Phase4,
  PROTOCOL_PHASE5: Constants.PRFOTOCOL_Phase5,

  EXECUE_FUNCTION_TYPE_RPR: 'rpr',
  EXECUE_FUNCTION_TYPE_DIAG: 'diag',

  // メモ情報
  MEMOINFO_MAX_LEN: Constants.MEMOINFO_MAX_LEN,
  MEMOINFO_DETA_NAME_MAX_LEN: Constants.MEMOINFO_DETA_NAME_MAX_LEN,

  // FFDプルダウンType
  FFD_TYPE_TENTATIVE: Constants.FFD_DATA_TYPE_TENTATIVE,
  FFD_TYPE_CONFIRM: Constants.FFD_DATA_TYPE_CONFIRM,

  // PFC repro type
  REPRO_TYPE_FORCE: Constants.REPRO_TYPE.FORCE,
  REPRO_TYPE_OPTIONAL: Constants.REPRO_TYPE.OPTIONAL,
  REPRO_TYPE_MERCHANTABILITY: Constants.REPRO_TYPE.MERCHANTABILITY,
  REPRO_TYPE_NOT_SET: Constants.REPRO_TYPE.NOT_SET,
  REPRO_TYPE_RESTORE: Constants.REPRO_TYPE.RESTORE,
  // 仕向け地判定情報 文字数
  REGIONINFORMATION_NAME_LEN: 2,

  // 会社グループ情報
  COUNTRY_GROUP_EUROPE: Constants.COUNTRY_GROUP_EUROPE,

  // conitoのUIから起動の場合の動作確認用情報
  STATE_EXTERNAL_AUTH_TEST: 'externalAuthTest',

  MAINTE_TYPE_VEHICLE_INFO: 'VEHICLEINFO',
  MAINTE_TYPE_REPRO_INFO: 'REPROINFO',

  // 車種情報-年改区分情報
  VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE: Constants.VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE,

  // マニュアルのタイプ
  MANUAL_TECH: 'technician',
  MANUAL_ADMIN: 'administrator'
});