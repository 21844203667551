import api from '../../../common/api';
import config from '../../../share/config';
import handler from '../../../share/util/apiResultValidator';
import loading from '../../../share/components/vue/ProcessingIcon';

export default {
  data() {
    return {
      user_name: '',
      firstName: '',
      lastName: '',
      email: '',
      processing: false
    };
  },

  components: {
    'processing-view': loading
  },

  computed: {
    isManadatoryFilled: {
      get: function () {
        if (this.user_name !== '' && ((this.firstName !== '' && this.lastName !== '') || this.email !== '')) {
          return true;
        }
        return false;
      },
    },

    isUserNameEnabled: {
      get() {
        if (this.firstName === '' && this.lastName === '' && this.email === '') {
          return true;
        }
        if (this.firstName !== '' || this.lastName !== '') {
          return true;
        }
        return false;
      }
    },

    isUserEmailEnabled: {
      get() {
        if (this.firstName === '' && this.lastName === '' && this.email === '') {
          return true;
        }
        if (this.email !== '') {
          return true;
        }
        return false;
      }
    },
  },

  methods: {

    executeInitPassword: function () {
      if (this.isManadatoryFilled) {
        this.didTapInitPasswordButton();
      }
    },

    // Function for handling the init password button action.
    didTapInitPasswordButton: async function () {
      if (this.processing) return;

      const param = { user_id: this.user_name, first_name: this.firstName, family_name: this.lastName, email_address: this.email };

      this.processing = true;
      const response = await api.postCall(config.ADMIN, '/initPassword', param);
      this.processing = false;

      // APIレスポンスの検証
      handler.validate(
        handler.validateTypes.all, response, this, null,
        () => {
          this.$router.push({ name: "login" });
        },
        (result) => {
          // メンテナンスエラーのみログイン画面に戻す 
          if (result.causeType === handler.causeTypes.maintenanceError) {
            this.$router.push({ name: "login" });
          }
          else {
            this.setDefaultFocus();
          }
        });
    },

    onTapBackButton: function () {
      if (!this.processing) {
        this.$router.push({ name: "login" });
      }
    },

    setDefaultFocus: function () {
      setTimeout(() => {
        document.getElementById("id-username").focus();
      }, 250);
    },
  },

  mounted() {
    this.setDefaultFocus();
  }
};