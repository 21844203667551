<div class="container">
  <div class="row">
    <!-- お知らせ -->
    <div class="col-12 mt-4">
      <cstm-card headerTitle="message.header_home_notification" @expandHeader="cardCollapsed">
        <template v-slot:body>
          <b-overlay :show="notificationLoading" opacity="0.3" spinner-variant="primary" no-fade>
            <div class="m-4">
              <div v-html="notification"></div>
            </div>
          </b-overlay>
        </template>
      </cstm-card>
    </div>
    <!-- 診断 -->
    <div class="col-12 mt-4">
      <b-img v-show="false" :src="imageSrc"></b-img>
      <div class="container-shadow">
        <div class="row row-50">
          <div class="col-12 label-sub-header">
            <label class="ml-2 mb-2">{{ $t('message.header_home_diagnosis') }}</label>
          </div>
          <!-- クラウドコネクタ選択 -->
          <div class="col-12 row">
            <!-- TODO v300 => v230 merge マスク対応 -->
            <div class="col-1 text-center">{{ $t('message.label_home_diagnosis_step1') }}</div>
            <!-- <div class="col-1 text-center">{{ $t('message.label_home_diagnosis_step1') }}
              <svg v-for="n of 6" :key="n" viewBox="0 0 16 16" class="icon-type ml-3 mt-1">
                <g transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)">
                  <path d="M10 125 c0 -8 64 -94 70 -94 6 0 70 86 70 94 0 3 -31 5 -70 5 -38 0-70 -2 -70 -5z" />
                </g>
              </svg>
            </div> -->
            <div class="col-3">
              <b-button variant="primary" v-on:click="selectCloudConnector()" class="step-button-style">
                {{ $t('message.button_home_select_cc') }}
              </b-button>
            </div>
            <div class="col-8">
              <div>{{ $t('message.message_home_diagnosis_select_cc') }}</div>
              <div v-if="enableDownload">
                <div class="mt-2">
                  {{ $t('message.message_home_diagnosis_download_cc')}}
                </div>
                <div class="col-12 row">
                  <div class="col-8"></div>
                  <div class="col-4">
                    <svg-btn normal variant="primary" iconKey="download" title="button_home_download_cc"
                      @onclick="download()" class="step-button-style" style="margin-left: 50px;"></svg-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <hr />
          </div>
          <!-- 車両情報の取得 -->
          <div class="col-12 row mb-3">
            <div class="col-1 text-center">{{ $t('message.label_home_diagnosis_step2') }}</div>
            <div class="col-3">
              <b-button variant="primary" v-on:click="openConfirmationModalForVehicleInfoAcquisition()" :disabled="isVehicleDisabled"
                class="step-button-style">
                {{ $t('message.button_home_vehicle_information') }}
              </b-button>
            </div>
            <div class="col-8">{{ $t('message.message_home_diagnosis_vehicle_information') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO v300 => v230 merge マスク対応 -->
    <!--リプログラミング -->
    <!--<div v-if="userInfo.isTechnicalHq" class="col-12 mt-4">
      <div class="container-shadow">
        <div class="row row-50">
          <div class="col-12 label-sub-header">
            <label class="ml-2 mb-2">{{ $t('message.header_home_repro_programming') }}</label>
          </div> -->
          <!-- 技本専用ページ -->
          <!--<div class="col-12 row">
            <div class="col-1 text-center"></div>
            <div class="col-3 mt-2 mb-3">
              <b-button variant="primary" class="step-button-style" v-on:click="moveToTechHqLogin()">
                {{ $t('message.button_home_repro_programming_gihon') }}
              </b-button>
            </div>
            <div class="col-8 mt-2">
              <div>{{ $t('message.massage_home_repro_programming_explanation') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 計測データ -->
    <!-- <div class="col-12 mt-4">
      <div class="container-shadow">
        <div class="row row-50">
          <div class="col-12 label-sub-header">
            <label class="ml-2 mb-2">{{ $t('message.header_home_measurement_data') }}</label>
          </div> -->
          <!-- 計測データの選択 -->
          <!-- <div class="col-12 row">
            <div class="col-1 text-center"></div>
            <div class="col-3 mt-2 mb-3">
              <b-button variant="primary" class="step-button-style" v-on:click="moveToDiagProjects()">
                {{ $t('message.button_home_measurement_data_confirmation') }}
              </b-button>
            </div>
            <div class="col-8 mt-2">
              <div>{{ $t('message.massage_home_measurement_data_explanation') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <!-- クラウドコネクターダウンロード用 -->
  <iframe style="display:none;" :src="fileUrl"></iframe>
  <processing-view :processing="processing"></processing-view>

  <!-- modal dialog  -->
  <div>
    <!-- Cloud Connector ID Input for Pairing -->
    <b-modal v-model="showConnecterModal" id="modal-netApp-input" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
      no-close-on-backdrop centered no-close-on-esc @ok="connectToNetApp(netAppId, true)">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.header_cloud_connect_title') }}
        </div>
      </template>

      <template v-slot:default>
        <div class="container-fluid mt-2 mb-2">
          <div class="row no-gutters">
            <div class="mb-3">{{ $t('message.label_cloud_connect_input_desc') }}</div>
            <div class="col-12">
              <b-form-group class="form-group">
                <label class="label-sm">{{ $t("message.label_cloud_connector") }}</label>
                <b-input-group>
                  <b-form-input type="text" v-model="netAppId" size="sm" autocomplete="off">
                    {{ netAppId }}
                  </b-form-input>
                  <!-- ブラウザによって仕様が異なる為現状は、非表示で対応。今後cloudconnecterIDペーストボタンを使用する可能性があるために残しておく -->
                  <b-input-group-append v-if=false>
                    <b-button variant="outline-primary" size="sm" class="ml-3" @click="pasteCloudConnectorId()">{{
                      $t('message.label_cloud_connect_input_paste') }}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="mt-2 mx-auto">
              <b-img class="cc-image" fluid :src="imageSrc"></b-img>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="showConnecterModal=false">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="connectToNetApp(netAppId, true)" class="ml-2 mr-2" :disabled="!netAppId">
            {{ $t("message.button_netapp_pairing") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- 車両情報取得中のダイアログ -->
    <b-modal v-model="showVehiclModal" id="modal-vehicle" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
      no-close-on-backdrop no-close-on-esc centered hide-footer>
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.header_vehicle_connect') }}
        </div>
      </template>

      <template v-slot:default>
        <div class="container-fluid mt-2 mb-2">
          <custom-Progress :value="progress" useResource :message="phaseMessage" />
        </div>
      </template>
    </b-modal>

    <!-- VIN 車台番号の入力 ダイアログ -->
    <b-modal v-model="showVINModal" id="modal-VIN" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
      no-close-on-backdrop no-close-on-esc centered>
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.header_vin_input_title') }}
        </div>
      </template>

      <template v-slot:default>
        <b-overlay :show="processing" opacity="0.8" spinner-variant="primary" no-fade>
          <div class="container-fluid mt-2 mb-2">
            <div class="row no-gutters">
              <div class="col-12 mb-4 text-center">
                <strong v-html="$t('message.header_vin_input_sub_titile')"></strong>
              </div>
              <div class="col-5">
                <b-form-group class="form-group">
                  <!-- TODO v300 => v230 merge マスク対応 -->
                  <label class="label-sm">{{ $t("message.label_vin_number") }}</label>
                  <!-- <label class="label-sm">{{ $t("message.label_vin_number") }}<span class="input-attention-required">{{
                      $t("message.alert_vinz_input_required") }}</span>
                  </label> -->
                  <b-form-input type="text" v-model="vinNumber" size="sm" autocomplete="off"
                    :state="isAlert.vinNumber.valid" :disabled="!isVinNUmber">
                    {{ vinNumber }}
                  </b-form-input>
                  <b-form-invalid-feedback>{{ $t(isAlert.vinNumber.msg, [isAlert.vinNumber.length]) }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-1 text-center" style="margin-top: 34px;">
                {{ $t('message.label_or') }}
              </div>
              <div class="col-6">
                <div class="row no-gutters">
                  <div class="col-12">
                    <label class="label-sm">{{ $t("message.label_chassis_number") }}</label>
                    <!-- TODO v300 => v230 merge マスク対応 -->
                    <!-- <label class="label-sm">{{ $t("message.label_chassis_number") }}<span class="input-attention-required ">{{
                        $t("message.alert_vinz_input_required") }}</span></label> -->
                  </div>
                  <div class="col-4">
                    <b-form-group class="form-group">
                      <b-form-input type="text" v-model="chassisNumber1" size="sm" autocomplete="off"
                      :state="isAlert.chassisNumber1.valid" :disabled="!isClassificationNumberEnabled">
                        {{ chassisNumber1 }}
                      </b-form-input>
                      <b-form-invalid-feedback>{{ $t(isAlert.chassisNumber1.msg, [isAlert.chassisNumber1.length]) }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-1 text-center mt-1">
                    {{ $t('message.common_hyphen') }}
                  </div>
                  <div class="col-7">
                    <b-form-group class="form-group">
                      <b-form-input type="text" v-model="chassisNumber2" size="sm" autocomplete="off"
                        :state="isAlert.chassisNumber2.valid" :disabled="!isClassificationNumberEnabled">
                        {{ chassisNumber2 }}
                      </b-form-input>
                      <b-form-invalid-feedback>{{ $t(isAlert.chassisNumber2.msg, [isAlert.chassisNumber2.length]) }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <!-- VINTYPEがtrueの場合 -->
                  <div v-if="isVinTypeUnknown" class="col-12 mt-3">
                    <b-form-group class="form-group">
                      <label class="label-sm">{{ $t("message.label_applied_model") }}</label>
                      <!-- TODO v300 => v230 merge マスク対応 -->
                      <!-- <label class="label-sm">{{ $t("message.label_applied_model") }}<span class="input-attention-required ">{{
                          $t("message.alert_vinz_input_required") }}</span></label> -->
                      <b-form-input type="text" v-model="appliedModel" size="sm" autocomplete="off"
                        :state="isAlert.appliedModel.valid" :disabled="!isAppliedModelEnabled">
                        {{ appliedModel }}
                      </b-form-input>
                      <b-form-invalid-feedback>{{ $t(isAlert.appliedModel.msg, [isAlert.appliedModel.length]) }}</b-form-invalid-feedback>
                    </b-form-group>
                    <!-- TODO v300 => v230 merge マスク対応 -->
                    <!-- <div class="row no-gutters">
                      <div class="col-5 mt-3">
                        <label class="label-sm">{{ $t("message.label_registration_number") }}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-2">
                        <b-form-checkbox v-model="isVehicleInspection" @change="checkboxChanged"
                          :disabled="!isChassisNumber">
                          {{ $t("message.label_no_car_verification") }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_region_name") }}</label>
                          <filter-dropdown :options="areaNameList" ref="fltdlpAreaName" v-model="selectedAreaName"
                            @selected="dropdownSelectedAreaName" :disabled="!isChassisNumber||isVehicleInspection">
                          </filter-dropdown>
                        </b-form-group>
                      </div>
                      <div class="col-7">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_classification_number") }}</label>
                          <b-form-input type="text" v-model="category" size="sm" autocomplete="off"
                            :disabled="!isChassisNumber||isVehicleInspection" :state="isAlert.category.valid">
                            {{ category }}
                          </b-form-input>
                          <b-form-invalid-feedback>{{ $t(isAlert.category.msg, [isAlert.category.length]) }}</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_hiragana") }}</label>
                          <filter-dropdown :options="hiraganaList" ref="fltdlpHirgana" v-model="selectedHiragana"
                            @selected="dropdownSelectedHiragana" :disabled="!isChassisNumber||isVehicleInspection">
                          </filter-dropdown>
                        </b-form-group>
                      </div>
                      <div class="col-8">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_individual_number") }}</label>
                          <b-form-input type="text" v-model="individualNumber" size="sm" autocomplete="off"
                            :disabled="!isChassisNumber||isVehicleInspection" :state="isAlert.individualNumber.valid">
                            {{ individualNumber }}
                          </b-form-input>
                          <b-form-invalid-feedback>{{ $t(isAlert.individualNumber.msg, [isAlert.individualNumber.length]) }}</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div> -->
                  </div>
                  <!-- VINTYPEがfalseの場合 -->
                  <div v-else class="col-12">
                    <div class="row no-gutters">
                      <div class="col-5 mt-3">
                      <!-- TODO v300 => v230 merge マスク対応 -->
                      <!-- <div class="col-6 mt-3"> -->
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_applied_model") }}</label>
                          <!-- TODO v300 => v230 merge マスク対応 -->
                          <!-- <label class="label-sm">{{ $t("message.label_applied_model") }}<span class="input-attention-required">{{
                              $t("message.alert_vinz_input_required") }}</span></label> -->
                          <b-form-input type="text" v-model="appliedModel" size="sm" autocomplete="off"
                            :state="isAlert.appliedModel.valid" :disabled="!isAppliedModelEnabled">
                            {{ appliedModel }}
                          </b-form-input>
                          <b-form-invalid-feedback>{{ $t(isAlert.appliedModel.msg, [isAlert.appliedModel.length]) }}</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-2 text-center" style="margin-top: 52px;">
                        <label class="label-sm">{{ $t('message.label_or') }}</label>
                      </div>
                      <div class="col-5 mt-3">
                      <!-- TODO v300 => v230 merge マスク対応 -->
                      <!-- <div class="col-4 mt-3"> -->
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_classification") }}</label>
                          <!-- TODO v300 => v230 merge マスク対応 -->
                          <!-- <label class="label-sm">{{ $t("message.label_classification") }}<span
                              class="input-attention-required ">{{ $t("message.alert_vinz_input_required") }}</span></label> -->
                          <b-form-select v-model="selectedClassification" size="sm" :disabled="!isClassificationEnabled"
                            :state="isAlert.classification.valid" class="custom-select custom-select-sm">
                            <option v-for="classification in classificationList" :value="classification">
                              {{ classification }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback>{{ $t(isAlert.classification.msg) }}</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <!-- TODO v300 => v230 merge マスク対応 -->
                    <!-- <div class="row no-gutters">
                      <div class="col-5 mt-3">
                        <label class="label-sm">{{ $t("message.label_registration_number") }}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-2">
                        <b-form-checkbox v-model="isVehicleInspection" @change="checkboxChanged"
                          :disabled="!isChassisNumber">
                          {{ $t("message.label_no_car_verification") }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_region_name") }}</label>
                          <filter-dropdown :options="areaNameList" ref="fltdlpAreaName"  v-model="selectedAreaName"
                            @selected="dropdownSelectedAreaName" :disabled="!isChassisNumber||isVehicleInspection">
                          </filter-dropdown>
                        </b-form-group>
                      </div>
                      <div class="col-7">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_classification_number") }}</label>
                          <b-form-input type="text" v-model="category" size="sm" autocomplete="off"
                            :disabled="!isChassisNumber||isVehicleInspection" :state="isAlert.category.valid">
                            {{ category }}
                          </b-form-input>
                          <b-form-invalid-feedback>{{ $t(isAlert.category.msg, [isAlert.category.length]) }}</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_hiragana") }}</label>
                          <filter-dropdown :options="hiraganaList" ref="fltdlpHirgana" v-model="selectedHiragana"
                            @selected="dropdownSelectedHiragana" :disabled="!isChassisNumber||isVehicleInspection">
                          </filter-dropdown>
                        </b-form-group>
                      </div>
                      <div class="col-8">
                        <b-form-group class="form-group">
                          <label class="label-sm">{{ $t("message.label_individual_number") }}</label>
                          <b-form-input type="text" v-model="individualNumber" size="sm" autocomplete="off"
                            :disabled="!isChassisNumber||isVehicleInspection" :state="isAlert.individualNumber.valid">
                            {{ individualNumber }}
                          </b-form-input>
                          <b-form-invalid-feedback>{{ $t(isAlert.individualNumber.msg, [isAlert.individualNumber.length]) }}</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="closeVinModal()"
            :disabled="processing">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="updateVinNumber()" class="ml-2 mr-2" :disabled="processing">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- SBRリプロ評価者 リプロ仕向け地選択ダイアログ -->
    <b-modal v-model="showRegionSelectModal" id="modal-region-select" size="lg" header-bg-variant="secondary"
      header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc @ok="showIgOnDialog()">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.header_region_select') }}
        </div>
      </template>
      <template v-slot:default>
        <label class="text-center w-100 mt-3">
          {{ $t('message.message_region_select') }}
        </label>
        <div class="text-center w-100 mt-2">
          <b-form-radio-group v-model="selectedReproRegion" :options="reproRegionList"></b-form-radio-group>
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="showRegionSelectModal=false">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="showIgOnDialog()" class="ml-2 mr-2">
            {{ $t("message.common_button_select") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- 車両情報取得直前IG_ONダイアログ -->
    <b-modal v-model="showIgOnModal" id="modal-ig-on" size="lg" header-bg-variant="secondary" header-text-variant="light"
      no-stacking no-close-on-backdrop centered no-close-on-esc @ok="startConnectionToVehicle()">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.header_vehicle_information_confirm_ig_on') }}
        </div>
      </template>

      <template v-slot:default>
        <div class="text-center w-100 mt-3 checkbox-size-lg">
          <b-form-checkbox v-model="executingIgOn">
            {{ $t('message.label_vehicle_information_ig_on') }}
          </b-form-checkbox>
        </div>
        <label class="text-center w-100 mt-3">
          {{ $t('message.message_vehicle_information_ig_on_attention') }}
        </label>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="cancelIgnition()">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="startConnectionToVehicle()" class="ml-2 mr-2" :disabled="!executingIgOn">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Queue 表示方式ダイアログ SU2対応 -->
    <b-modal v-model="showModalQueueWarning" id="modal-queue-warning" size="lg" header-bg-variant="warning" header-text-variant="light" no-close-on-backdrop
      no-stacking centered no-close-on-esc @ok="closeModalQueue()" @close="exitFromConnection()" @hidden="callModalHiddenEvent">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.common_title_warning') }}
        </div>
      </template>

      <template v-slot:default>
        <div>
          <div v-html="modalQueueMsg.lead" class="text-left w-100 mt-3"></div>
        </div>
        <div v-if="modalQueueMsg.tail" class="rxswin-warn-style mt-3">
          <p v-html="modalQueueMsg.tail" class="p-3"></p>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button v-if="needExitSequence" size="sm" variant="outline-primary" class="ml-2 mr-2" @click="exitFromConnection()">
            {{ $t('message.button_polling_alert_terminate')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="closeModalQueue()" class="ml-2 mr-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</div>