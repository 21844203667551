export default {
  namespaced: true,

  state: {
    currentProvider: '' //SAML連携のユーザーの所属(stg1環境用)
  },

  mutations: {

    setCurrentProvider(state, payload) {
      if (state.currentProvider === '') {
        state.currentProvider = payload;
      }
    }
  },

  actions: {
    setCurrentProvider(context, payload) {
      context.commit('setCurrentProvider', payload);
    }
  },
};
