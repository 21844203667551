<div class="container-fluid">
  <div class="row">
    <div class="col-12 mt-1">
      <div class="project-tbl-style">
        <div class="row width-max">
          <div class="col-12 header-buttom-line">
            <label class="header-title-style mt-3">{{ $t('message.header_measured_data_details') }}</label>
          </div>
          <div class="col-12 mt-2"><label class="header-id-style">{{ projectDetails.id }}</label></div>
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-4"><label>{{ $t('message.label_measured_data_details_registered_date') }}</label>
                  </div>
                  <div class="col-8"><label>{{ projectDetails.registered_at }}</label></div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4"><label>{{ $t('message.label_measured_data_details_registrants') }}</label></div>
                  <div class="col-8"><label>{{ projectDetails.belongs }} {{ projectDetails.registered_by }}</label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4"><label>{{ $t('message.label_measured_data_details_vin') }}</label></div>
                  <div class="col-8"><label>{{ projectDetails.vin }}</label></div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4"><label>{{ $t('message.label_measured_data_details_vehicle') }}</label></div>
                  <div class="col-8"><label>
                      {{ projectDetails.vehicle_name }} {{ projectDetails.model_year || projectDetails.classification +
                      ' ' }}
                    </label></div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4"><label>{{ $t('message.label_measured_data_details_registration_number') }}</label>
                  </div>
                  <div class="col-8"><label>
                      {{ projectDetails.area_name }} {{ projectDetails.category }} {{ projectDetails.hiragana }} {{
                      projectDetails.individual_number }}
                    </label></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="">
              <b-form-group class="form-group">
                <label for="diag_comment">{{ $t("message.label_measured_data_details_comment") }}</label>
                <b-form-textarea v-model="projectDetails.comment" id="diag_comment" size="sm" autocomplete="off"
                :state="isAlert.comment.valid" rows="3" max-rows="3">
                  {{ projectDetails.comment }}
                </b-form-textarea>
                <b-form-invalid-feedback>{{ $t(isAlert.comment.msg,
                  [isAlert.comment.length]) }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="text-center">
              <svg-button variant="primary" normal size="sm" iconKey="update" title="common_button_update"
                @onclick="openUpdateConfirmModal()" />
            </div>
          </div>
          <div class="col-1"></div>

          <div class="col-12">
            <div>
              <b-nav tabs>
                <b-nav-item active>{{ $t('message.label_measured_data_details_all_dtc') }}</b-nav-item>
              </b-nav>
            </div>
            <div>
              <cstm-tbl type="simple" :columns="detailsColumns" :items="detailsDataTable" useSort hasTabs rowSelect
                @rowClicked="goToDataRecollection" @rowButtonClicked="openEditNoteModal" useStripeStyle
                currentSortkey="updated_at" class="mt-2 ml-2">
                <template v-slot:rowButton>
                  {{ $t('message.common_button_edit') }}
                </template>
              </cstm-tbl>
            </div>
          </div>
          <div class="col-12 text-right mb-2">
            <b-button size="sm" variant="primary" @click="backToProjectList()">
              {{ $t('message.common_button_back') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <b-modal id="modal-edit" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
      no-close-on-backdrop centered no-close-on-esc>
      <template v-slot:modal-header>
        <div class="text-center w-100">
          {{ $t('message.label_measured_data_details_edit') }}
        </div>
      </template>

      <template v-slot:default>
        <b-overlay :show="modalLoading" opacity="0.8" spinner-variant="primary" rounded="lg">
          <div class="container-fluid">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="row">
                  <div class="col-3">{{ $t('message.label_measured_data_details_update_time') }}</div>
                  <div class="col-9">
                    <label>{{ selectedUpdatedTime }}</label>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-3">
                    <label>{{ $t('message.label_vehicle_dtc_note_modal_data_name') }}
                      <span class="input-attention-required ">{{ $t("message.alert_vinz_input_required") }}</span>
                    </label>
                  </div>
                  <div class="col-9">
                    <b-form-input type="text" v-model="filledDataName" size="sm" :state="isAlert.filledDataName.valid">
                      {{ filledDataName }}
                    </b-form-input>
                    <b-form-invalid-feedback>{{ $t(isAlert.filledDataName.msg, [isAlert.filledDataName.length]) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-3">{{ $t('message.label_measured_data_details_notes') }}</div>
                  <div class="col-9">
                    <b-form-textarea type="text" v-model="filledDataNote" size="sm" autocomplete="off" rows="4"
                      max-rows="4" :state="isAlert.filledDataNote.valid">
                      {{ filledDataNote }}
                    </b-form-textarea>
                    <b-form-invalid-feedback>{{ $t(isAlert.filledDataNote.msg, [isAlert.filledDataNote.length]) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>

      <template v-slot:modal-footer="{ close, ok }">
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="close()" :disabled="modalLoading">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <svg-button variant="primary" normal size="sm" iconKey="update" title="common_button_update"
            @onclick="updateNote()" class="ml-2 mr-2" :disabled="modalLoading" />
        </div>
      </template>
    </b-modal>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>