<div class="container-fluid mt-2 mb-2">
	<div class="text-center">
		<canvas
			id="canvas"
			ref="canRef"
			:width="printImageWidth"
			:height="printImageHeight"
		></canvas>
	</div>
</div>
