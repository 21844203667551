export default {
  props: {
    iconKey: {
      type: String,
      required: true,
      default: false
    },
    // defaultはメニューアイコンのクラスを設定する。
    // 外部からスタイルを設定する場合は、scssに定義する。
    iconStyle: {
      type: String,
      required: false,
      default: 'menu-img-style'
    },
  },
  data() {
    return {
      // noop
    };
  },
  created() {
    // noop
  },
  mounted() {
    // noop
  },
  computed: {
    // no params
  },
  methods: {
    // noop
  },
  watch: {
    // noop
  }
};