const vehicleTestMode = require('../../data/testMode/vehicleInfo.json');

export default {
  namespaced: true,

  state: {
    // 車種情報
    vehicleInfo: null,
    // システムテーブル情報(ECU一覧テーブル情報)
    systemTable: null,
    // システムツリー情報(ECU一覧ツリー情報)
    systemTree: {},
    // 公開・非公開ファイル一覧の表示モード
    showPublished: true,
    // ダイアログのメッセージキャッシュ
    dialogContents: null,
    // 法規不適合システム一覧
    unconformedSystems: [],
    // プロジェクト一覧のキャッシュ
    projectListBuff: {},
    // 計測データのキャッシュ
    projectDetails: null,
    // FFD データのキャッシュ
    ffdStores: [],
    // リプロ対象ECUのROMID情報
    systemIdsInfo: { currentId: null, newIds: [] },
    // CCUリプロの情報
    ccuReproInfo: { systemName: null, newSwid: null, usbSwid: null , fileType: null, fileId: null },
    // データ再生画面で選択中の計測データをキャッシュ
    activeMeasuredDataId: '',
    // 通常画面・機能向上画面・以前バージョン画面のフラグ
    showDisplayVehicle: 'campaign',
  },

  mutations: {
    // 車種情報
    setVehicle(state, payload) {
      state.vehicleInfo = payload;
    },
    // システムテーブル情報(ECU一覧テーブル情報)
    setSystemTable(state, payload) {
      state.systemTable = payload;
    },
    // システムツリー情報(ECU一覧ツリー情報)
    setSystemTree(state, payload) {
      state.systemTree[payload.type] = payload.tbl;
    },
    // 公開・非公開ファイル一覧の表示モード
    setDisplayMode(state, payload) {
      state.showPublished = payload;
    },
    setUnconformedSystems(state, payload) {
      state.unconformedSystems = payload;
    },
    // リプロ対象ECUのROMID情報
    setSystemIdsInfo(state, payload) {
      state.systemIdsInfo = payload;
    },
    // CCUリプロの情報
    setCcuReproInfo(state, payload) {
      state.ccuReproInfo = payload;
    },
    setDiagProjectList(state, payload) {
      state.projectListBuff = payload;
    },
    setProjectDetails(state, payload) {
      state.projectDetails = payload;
    },
    setFFDStores(state, payload) {
      state.ffdStores = payload;
    },
    setActiveDataId(state, payload) {
      state.activeMeasuredDataId = payload;
    },
    // 通常画面・機能向上画面・以前バージョン画面のフラグ
    setDisplayVehicleMode(state, payload) {
      state.showDisplayVehicle = payload;
    }
  },

  actions: {
    cacheVehicleInfo(context, payload) {
      let obj = null;
      if (payload) {
        // 参照渡しをしないためコピーを実施
        obj = JSON.parse(JSON.stringify(payload));
        if (context.rootState.netAppConnector.isTestMode) {
          obj.vehicle = vehicleTestMode.vehicle;
        } else {
          if (Object.prototype.hasOwnProperty.call(obj.vehicle, 'battery_voltage')) {
            // battery_voltage key が存在した場合削除しておく 
            delete obj.vehicle['battery_voltage'];
          }
        }
      }
      context.commit('setVehicle', obj);
    },

    cacheSystemTable(context, payload) {
      context.commit('setSystemTable', payload);
    },

    cacheSystemTree(context, payload) {
      context.commit('setSystemTree', payload);
    },

    cacheDisplayMode(context, payload) {
      context.commit('setDisplayMode', payload);
    },

    cahceUnconformedSystems(context, payload) {
      context.commit('setUnconformedSystems', payload);
    },

    cacheSystemIdsInfo(context, payload) {
      context.commit('setSystemIdsInfo', payload);
    },

    cacheCcuReproInfo(context, payload) {
      context.commit('setCcuReproInfo', payload);
    },

    // TODO mutations プロパティで setDialogContents が未定義
    // アプリ内からの呼び出しがないため不要であれば削除すること
    cacheDialogContents(context, payload) {
      context.commit('setDialogContents', payload);
    },

    cacheUnconformedSystems(context, payload) {
      context.commit('setUnconformedSystems', payload);
    },

    cacheDiagProjectList(context, payload) {
      context.commit('setDiagProjectList', payload);
    },

    cacheProjectDetails(context, payload) {
      context.commit('setProjectDetails', payload);
    },

    cacheFFDStores(context, payload) {
      context.commit('setFFDStores', payload);
    },

    cacheActiveDataId(context, payload) {
      context.commit('setActiveDataId', payload);
    },

    cacheDisplayReproTypeMode(context, payload) {
      context.commit('setDisplayVehicleMode', payload);
    },
  }
};
