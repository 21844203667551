import {
  headerType_none,
  headerType_start,
  headerType_vehicle,
  headerType_result
} from "../constants/uiConfigs";

import util from "@/share/util/utils";
import config from "@/share/config";

import blank from '@/components/vue/Blank';
import termsAgreement from '@/components/vue/TermsAgreement';
import changePassword from "@/components/vue/ChangePassword";
import informationMain from '@/components/vue/InformationMain';
import systemTable from '@/components/vue/SystemTable';
import systemInformation from '@/components/vue/SystemInformation';
import attention from '@/components/vue/AttentionRepro';
import execution from '@/components/vue/ExecuteRepro';
import result from '@/components/vue/ReproResult';
import completeNewPasswordChallenge from "@/components/vue/CompleteNewPasswordChallenge";
import home from '@/components/vue/Home';
import initPassword from '@/components/vue/InitPassword';
import suSystemTable from '@/components/vue/SuSystemTable';
import usbVerificationFailed from '@/components/vue/UsbVerificationFailed';
import diagProjectList from '@/components/vue/DiagProjectList';
import dtcTable from '@/components/vue/DtcTable';
import menu from '@/components/vue/Menu';
import informationTabs from '@/components/vue/InformationTabs';
import diagProjectDetails from '@/components/vue/DiagProjectDetails';
import dataRecollection from '@/components/vue/DataRecollection';
import oldBrowserError from '@/share/components/vue/OldBrowserError';
import soldVehicleSystemTable from '@/components/vue/SoldVehicleSystemTable';
import soldInformationMain from '@/components/vue/SoldInformationMain';

// ログイン画面は日本と中国で分ける
const login = util.isChinaResion()
  ? require('@/components/vue/LoginChina').default
  : require('@/components/vue/Login').default;

/* eslint-disable max-lines */
export const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
    props: true
  },
  {
    path: '/old-browser',
    name: 'oldBrowserError',
    component: oldBrowserError,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: '/blank',
    name: 'blank',
    component: blank,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      breadCrumbLevel: 1,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
    props: true,
  },
  {
    path: '/terms-agreement',
    name: 'termsAgreement',
    component: termsAgreement,
    meta: {
      requiresAuth: true,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: changePassword,
    meta: {
      requiresAuth: true,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: "/complete-new-password-challenge",
    name: "completeNewPasswordChallenge",
    component: completeNewPasswordChallenge,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
    props: true,
  },
  {
    path: "/init-password",
    name: "initPassword",
    component: initPassword,
    meta: {
      requiresAuth: false,
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 2,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
    props: true,
  },
  {
    path: '/menu',
    name: 'menu',
    component: menu,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 3,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
  },
  {
    path: '/diag-project-list',
    name: 'diagProjectList',
    component: diagProjectList,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 3,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
  },
  {
    path: '/diag-project-details',
    name: 'diagProjectDetails',
    component: diagProjectDetails,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 3,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "",
    },
  },
  {
    path: '/data-recollection',
    name: 'dataRecollection',
    component: dataRecollection,
    meta: {
      requiresAuth: true,
      breadCrumbLevel: 4,
      headerType: headerType_start,
      flowNavigationType: config.NAVIGATION_DISPLAY,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "container-execute",
    },
    props: true,
  },
  {
    path: '/information-Main',
    name: 'informationMain',
    component: informationMain,
    props: true,
    children: [
      {
        path: '/information-tabs',
        name: 'informationTabs',
        component: informationTabs,
        props: true,
        children: [
          {
            path: '/system-table',
            name: 'systemTable',
            component: systemTable,
            meta: {
              requiresAuth: true,
              // TODO v300 => v230 merge マスク対応 ----------
              // breadCrumbLevel: 4,
              // --------------------------------------------
              breadCrumbLevel: 3,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            }
          },
          {
            path: '/su-system-table',
            name: 'suSystemTable',
            component: suSystemTable,
            meta: {
              requiresAuth: true,
              // TODO v300 => v230 merge マスク対応 ----------
              // breadCrumbLevel: 4,
              // --------------------------------------------
              breadCrumbLevel: 3,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            }
          },
          {
            path: '/dtc-table',
            name: 'dtcTable',
            component: dtcTable,
            meta: {
              requiresAuth: true,
              // TODO v300 => v230 merge マスク対応 ----------
              // breadCrumbLevel: 4,
              // --------------------------------------------
              breadCrumbLevel: 3,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
          {
            path: '/system-information',
            name: 'systemInformation',
            component: systemInformation,
            meta: {
              requiresAuth: true,
              // TODO v300 => v230 merge マスク対応 ----------
              // breadCrumbLevel: 5,
              // --------------------------------------------
              breadCrumbLevel: 4,
              headerType: headerType_vehicle,
              flowNavigationType: config.NAVIGATION_DISPLAY,
              viewProcessMode: config.PROCESS_IDLING,
              containerStyle: "container-execute",
            },
          },
        ]
      },
      {
        path: '/attention',
        name: 'attention',
        component: attention,
        meta: {
          requiresAuth: true,
          // TODO v300 => v230 merge マスク対応 ----------
          // breadCrumbLevel: 6,
          // --------------------------------------------
          breadCrumbLevel: 5,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_PROCESSING,
          containerStyle: "container-execute",
        }
      },
      {
        path: '/execution',
        name: 'execution',
        component: execution,
        meta: {
          requiresAuth: true,
          // TODO v300 => v230 merge マスク対応 ----------
          // breadCrumbLevel: 6,
          // --------------------------------------------
          breadCrumbLevel: 5,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_PROCESSING,
          containerStyle: "container-execute",
        },
        props: true,
      },
      {
        path: '/result',
        name: 'result',
        component: result,
        meta: {
          requiresAuth: true,
          // TODO v300 => v230 merge マスク対応 ----------
          // breadCrumbLevel: 7,
          // --------------------------------------------
          breadCrumbLevel: 6,
          headerType: headerType_result,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_COMPLETED,
          containerStyle: "container-execute",
        },
        props: true,
      },
      {
        path: '/usb-verification-failed',
        name: 'usbVerificationFailed',
        component: usbVerificationFailed,
        meta: {
          requiresAuth: true,
          // TODO v300 => v230 merge マスク対応 ----------
          // breadCrumbLevel: 5,
          // --------------------------------------------
          breadCrumbLevel: 4,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_REPRO_SUB_FAILED,
          containerStyle: "container-execute",
        },
      },
    ],
  },
  {
    path: '/soldInformation-Main',
    name: 'soldInformationMain',
    component: soldInformationMain,
    props: true,
    children: [
      {
        path: '/sold-vehicle-system-table',
        name: 'soldVehicleSystemTable',
        component: soldVehicleSystemTable,
        meta: {
          requiresAuth: true,
          breadCrumbLevel: 3,
          headerType: headerType_vehicle,
          flowNavigationType: config.NAVIGATION_DISPLAY,
          viewProcessMode: config.PROCESS_IDLING,
          containerStyle: "container-execute",
        },
        props: true,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: login,
    meta: {
      headerType: headerType_none,
      flowNavigationType: config.NAVIGATION_NONE,
      viewProcessMode: config.PROCESS_IDLING,
      containerStyle: "common-main-no-nav",
    },
  },
];