import api from '../../common/api';
import config from '../../share/config';

export default {
  namespaced: true,
  
  mutations: {

  },

  actions: {
    //機能使用回数をカウントする
    usageCountUp (context, payload) {
      if(!context.rootState.userProperty.isTechnicalHq){
        const param = {
          //repro or diag
          count_target: payload.type,
          date: new Date().getTime()
        };
        //APIを投げて機能使用回数をcountする
        api.postCall(config.ADMIN, '/usageCount', param);
      }
      return;
    },
  }
};