import util from './utils';

// プロバイダーが一致するsamlAccountを抽出する
export const extractSamlAccount = (samlAccountList, provider) => {
  let extractedAccount = null;
  if (provider) {
    extractedAccount = samlAccountList.find(s => s.provider.includes(provider));
  }
  return extractedAccount;
};

// URL情報からprovider情報を抽出する
export const getProviderFromUrl = (url) => {
  let providerName = '';
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const stateList = params.get('state');
  if (stateList) {
    providerName = stateList.split(':')[1];
  } else {
    const multiDomainMap = util.getMultiDomainMap();
    const domainObj = multiDomainMap[urlObj.hostname.toLowerCase()];
    if (domainObj) {
      providerName = domainObj.provider;
    } else {
      // multiDomainMapに引きあたらなかった場合、デフォルト値を設定する
      providerName = 'SUBARU';
    }
  }
  return providerName;
};

// identity_providerからprovider情報を取得
export const getProviderFromIdentity = (url) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const providerName = params.get('identity_provider');
  return providerName;
};

export default {
  extractSamlAccount,
  getProviderFromUrl,
  getProviderFromIdentity
};