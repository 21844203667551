<div v-if="options" ref="input_form" class="fd__wrapper">
  <b-input-group size="sm">
    <span class="filter-ison-content">
      <svg class="icon-size" viewBox="0 0 24 24">
        <path
          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          style="fill: rgb(255, 255, 255);"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </span>
    <b-form-input
      type="text"
      autocomplete="off"
      class="form-control custom-select"
      @click="showOptions()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
    />
  </b-input-group>
  <!-- Dropdown Input -->

  <!-- Dropdown Menu -->
  <div class="dropdown-menu-content" 
    v-show="optionsShown"
    v-bind:style="{ width: menuWidth + 'px', 'max-height' : menuMaxheight + 'px' }" >
    <option v-if="filteredOptions.length === 0"></option>
    <option v-else
      class="dropdown-menu-item"
      @mousedown="selectItem(option)"
      v-for="(option, index) in filteredOptions"
      :key="index">
      {{ option.name || option.id || '-' }}
    </option>
  </div>
</div>