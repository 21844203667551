/* **
 * ログラッパ
 * @param label ログ出力時のラベル
 * @param value ログ出力時の値
 * @param outputTrace スタックトレースの表示有無 true:出力 false:出力無し 
 */
/* eslint-disable no-console */
export const log = (label, value = "", outputTrace = false) => {
  if (process.env.VUE_APP_DEBUG_LOG) {
    if (outputTrace) {
      console.trace();
    }
    console.log('[Log] ' + label, value);
  }
};

export default {
  log
};