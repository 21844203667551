<div class="container-fluid mt-3">
  <div class="row row-5">
    <div class="col-12" ref="cardBox">
      <ul class="nav nav-tabs pak-country-nav-tabs mt-3" role="tablist">
        <li v-for="tab in tabs" class="nav-item" role="presentation">
          <a v-bind:href="'#'" @click.prevent="switchCountryTab(tab.country)" role="tab"
            class="nav-link pak-country-nav-link" v-bind:class="{ 'active': tab.country === country }">{{ $t(tab.label)
            }}</a>
        </li>
      </ul>
      <customCard headerTitle="message.header_pak_search" :isCollapse="true" @expandHeader="cardCollapsed">
        <template v-slot:body>
          <div class="row text-left">
            <!-- Line 1 -->
            <div class="col-12">
              <div class="d-flex w-100">
                <!-- 日本 検索ボックス -->
                <div v-if="country === 'Domestic'" class="col-12 text-left mt-3">
                  <div class="d-flex w-100">
                    <!-- 書換後部番 -->
                    <div class="w-20">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_pack_number")
                          }}</label>
                        <b-form-select v-model="packNumber" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('pack_number')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- 車種 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_vehicle")
                          }}</label>
                        <b-form-select v-model="vehicle" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('vehicle')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- 型式 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{
                          $t("message.label_sold_vehicle_search_classification")
                          }}</label>
                        <b-form-select v-model="year" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('year')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- 年改 検索 -->
                      <b-form-group v-if="country === 'Domestic'" class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_model_line") }}</label>
                        <b-form-select v-model="modelLine" size="sm" class="custom-select custom-select-sm"
                          v-on:change="changeYearPullDownStatus">
                          <option v-for="item in getPullDownList('model_line')" :value="item"
                            v-on:change="changePullDownStatus">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <!-- エンジン 検索 -->
                    <div class="w-20 ml-2">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_engine") }}</label>
                        <b-form-select v-model="engine" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('engine')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- トランスミッション 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_t_m") }}</label>
                        <b-form-select v-model="transmission" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('transmission')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- その他 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_other") }}</label>
                        <b-form-select v-model="other" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('other')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <b-form-group class="form-group">
                        <!-- コントロールユニット情報 検索 -->
                        <label v-if="country === 'Domestic'" class="label-sm">{{
                          $t("message.label_sold_vehicle_search_unit") }}</label>
                        <label v-else class="label-sm">{{ $t("message.label_sold_vehicle_search_cpu")
                          }}</label>
                        <b-form-select v-model="affectedUnit" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('affected_unit')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <!-- 北米・欧州一般 検索ボックス -->
                <div v-else class="col-12 text-left mt-3">
                  <div class="d-flex w-100">
                    <!-- 書換後部番 -->
                    <div class="w-20">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_pack_number")
                          }}</label>
                        <b-form-select v-model="packNumber" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('pack_number')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- 車種 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_vehicle")
                          }}</label>
                        <b-form-select v-model="vehicle" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('vehicle')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- 年式 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{
                          $t("message.label_sold_vehicle_search_model_year")
                          }}</label>
                        <b-form-select v-model="year" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('year')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- 排ガス対応 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_emission_spec") }}</label>
                        <b-form-select v-model="specification" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('specification')" :value="item">{{ specification }}
                          </option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <!-- エンジン 検索 -->
                    <div class="w-20 ml-2">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_engine") }}</label>
                        <b-form-select v-model="engine" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('engine')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <!-- 吸気方式 検索 -->
                    <div class="w-20 ml-2">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_aspiration") }}</label>
                        <b-form-select v-model="aspiration" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('aspiration')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <!-- トランスミッション 検索 -->
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_t_m") }}</label>
                        <b-form-select v-model="transmission" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('transmission')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="w-20 ml-2">
                      <b-form-group class="form-group">
                        <!-- CPU情報 検索 -->
                        <label class="label-sm">{{ $t("message.label_sold_vehicle_search_cpu")
                          }}</label>
                        <b-form-select v-model="affectedUnit" size="sm" class="custom-select custom-select-sm">
                          <option v-for="item in getPullDownList('affected_unit')" :value="item">{{ item }}</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- Line 2 -->
            <div class="col-12">
              <div class="d-flex justify-content-between mb-3">
                <div style="visibility:hidden"></div>
                <div>
                  <svg-button normal variant="primary" size="sm" iconKey="search" title="common_button_search"
                    @onclick="executeSearch()">
                  </svg-button>
                </div>
                <div>
                  <a class="clear-atag-style" href="javascript:void(0)" v-on:click="clear()">
                    {{ $t("message.common_button_clear") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </customCard>
      <div class="col-12">
        <cstm-table ref="custom_table" currentSortkey="create_at" useSort initSortDir="asc" rowSelect
          :columns="switchCountryColums()" :items="items" class="mt-2 ml-2" @rowClicked="clickRow"
          v-bind:maxTableHeight="tableHeight">
          <template v-slot:contentRightEnd>
            <cstm-pagination class="mb-1" ref="pagination" v-bind:pageSize='pageSize' v-bind:initPage=1
              v-bind:totalPageLength='itemLength' @selecedPage="selectedPAKPage" inRprService>
            </cstm-pagination>
          </template>
        </cstm-table>
      </div>
      <!-- PAK詳細ダイアログ表示 -->
      <div v-if="pakFileDetailDialog">
        <b-modal v-model="pakFileDetailDialog" header-bg-variant="primary" header-text-variant="light" no-stacking
          no-close-on-backdrop no-close-on-esc centered size="xl">
          <template v-slot:header>
            <div class="text-center w-100 ">{{ $t("message.header_pak_details") }}</div>
          </template>
          <template v-slot:default>
            <b-overlay :show="processing" opacity="0.8" spinner-variant="primary" no-fade>
              <easy-table :columns="switchCountryColums()" :items="selectItems" class="mt-2 ml-2"
                type="simple"></easy-table>
              <!-- 仕向け地：日本 -->
              <div v-if="country === 'Domestic'">
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_target_parts_number") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].parts_number" size="sm" class="textarea-bg-white"
                      :disabled=true>
                      {{ selectItems[0].parts_number }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_sum_value") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].check_sum" size="sm" class="textarea-bg-white"
                      :disabled=true>
                      {{ selectItems[0].check_sum }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_short_connector") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].short_connector" size="sm"
                      class="textarea-bg-white" :disabled=true>
                      {{ selectItems[0].short_connector }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_connection") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].necessary_connector" size="sm"
                      class="textarea-bg-white" :disabled=true>
                      {{ selectItems[0].necessary_connector }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <div>
                    <label>{{ $t("message.label_pak_file_details_comment") }}</label>
                  </div>
                  <b-form-textarea class="textarea-bg-white" :disabled=true
                    v-model="selectItems[0].problem_fixed_history">{{
                    selectItems[0].problem_fixed_history }}</b-form-textarea>
                </div>
                <div class="col-12 text-left mt-2">
                  <div>
                    <label>{{ $t("message.label_pak_file_details_caution") }}</label>
                  </div>
                  <b-form-textarea class="textarea-bg-white" :disabled=true v-model="selectItems[0].attention">{{
                    selectItems[0].attention }}</b-form-textarea>
                </div>
              </div>
              <!-- 仕向け地：北米・欧州一般 -->
              <div v-else>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_target_parts_number") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].parts_number" size="sm" class="textarea-bg-white"
                      :disabled=true>
                      {{ selectItems[0].parts_number }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_appricable_cid") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].applicable_cid" size="sm"
                      class="textarea-bg-white" :disabled=true>
                      {{ selectItems[0].applicable_cid }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_new_cid") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].cid" size="sm" class="textarea-bg-white"
                      :disabled=true>
                      {{ selectItems[0].cid }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_sum_value") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].check_sum" size="sm" class="textarea-bg-white"
                      :disabled=true>
                      {{ selectItems[0].check_sum }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_connection") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].necessary_connector" size="sm"
                      class="textarea-bg-white" :disabled=true>
                      {{ selectItems[0].necessary_connector }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <b-form-group class="form-group">
                    <label>{{ $t("message.label_pak_file_details_update") }}</label>
                    <b-form-input type="text" v-model="selectItems[0].problem_fixed_history" size="sm"
                      class="textarea-bg-white" :disabled=true>
                      {{ selectItems[0].problem_fixed_history }}</b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 text-left mt-2">
                  <div>
                    <label>{{ $t("message.label_pak_file_details_note") }}</label>
                  </div>
                  <b-form-textarea class="textarea-bg-white" :disabled=true v-model="selectItems[0].grade">{{
                    selectItems[0].grade }}</b-form-textarea>
                </div>
              </div>
              <div class="mt-4 ml-3">
                <label class="mr-3">{{ $t("message.label_pak_file_details_keyword") }}</label>
                <b-button size="sm" variant="primary" @click="copyPassword()">
                  {{ $t("message.button_copy_password") }}
                </b-button>
              </div>
            </b-overlay>

          </template>
          <template v-slot:footer>
            <div class="col-12 text-center">
              <b-button size="sm" variant="outline-primary" class="mr-2" @click="closeModal()" :disabled="processing">
                {{ $t("message.common_button_cancel") }}
              </b-button>
              <b-button size="sm" variant="primary" @click="executeFw2Repro()" :disabled="processing">
                {{ $t("message.button_launch_fw") }}
              </b-button>

            </div>
          </template>
        </b-modal>
      </div>
    </div>
    <processing-view :processing="processing"></processing-view>
  </div>
</div>