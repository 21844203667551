<div>
  <div class="row no-gutters" ref="tableHeader">
    <div class="col-auto">
      <slot name="subButtonContainer"></slot>
    </div>
    <div class="col justify-content-end">
      <slot name="contentRightEnd"></slot>
    </div>
  </div>

  <hr class="hr-line-100">

  <!-- simple table  -->
  <div class="table-responsive">
    <table class="table table-sm easy-table overflow-auto" v-bind:class="{ 'table-active' : inActive }">
      <tr v-if="columns">
        <th v-for="(column, i) in columns" :key="i" :style="{ 'min-width': column.minWidth}">
          <div class="table-header-cursor" v-if="useSort" @click="sort(column)">
            <span>| {{ $t(column.label) }}</span>
            <span v-if="currentSort == column.name" v-html="getSortIcon()"></span>
          </div>
          <div v-else>
            <span>| {{ $t(column.label) }}</span>
          </div>
        </th>
      </tr>
      <tr v-for="(data, index) in (sortedActivity)" :key="index" v-bind:class="{'body-event-none' : !rowSelect}"
        v-on:click="rowClicked(data)">
        <td v-for="(column, i) in columns" :key="i" v-bind:class="data.bgStyle">
          <span v-if="column.useResource" v-bind:class="getColumnClasses(data, column.name)"
            v-html="$t(data[column.name])">
          </span>
          <span v-else v-bind:class="getColumnClasses(data, column.name)"
            v-html="data[column.name]">
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>