<div class="container-fluid ml-n2">
  <div class="row">
    <div class="col-5 text-left mt-2">
      <div class="div-usage-guide-style">
        <div class="div-usage-guide-message-style">
          <svg width="50" height="25" viewBox="0, 0, 50, 30" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="50" height="25" rx="5" ry="5" fill="rgb(71, 201, 84)" />
          </svg>
        </div>
        <div class="div-usage-guide-message-style">
          <span class="ml-2">{{ $t('message.message_vehicle_system_guide_update_unset_front') }}</span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
              stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h13M12 5l7 7-7 7" />
            </svg>
            <span>{{ $t('message.message_vehicle_system_guide_update_unset_rear') }}</span>
          </div>
        </div>
      </div>
      <div class="div-usage-guide-style">
        <div class="div-usage-guide-message-style">
          <svg width="50" height="25" viewBox="0, 0, 50, 30" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="50" height="25" rx="5" ry="5" fill="rgb(61, 144, 252)" />
          </svg>
        </div>
        <div class="div-usage-guide-message-style">
          <span class="ml-2">{{ $t('message.message_vehicle_system_guide_update_consented_front') }}</span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
              stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h13M12 5l7 7-7 7" />
            </svg>
            <span>{{ $t('message.message_vehicle_system_guide_update_consented_rear') }}</span>
          </div>
        </div>
      </div>
      <div class="div-usage-guide-style">
        <div class="div-usage-guide-message-style">
          <svg width="50" height="25" viewBox="0, 0, 50, 30" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="50" height="25" rx="5" ry="5" fill="rgb(255, 215, 0)" />
          </svg>
        </div>
        <div class="div-usage-guide-message-style">
          <span class="ml-2">{{ $t('message.message_vehicle_system_guide_update_rejected_front') }}</span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
              stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h13M12 5l7 7-7 7" />
            </svg>
            <span>{{ $t('message.message_vehicle_system_guide_update_rejected_rear') }}</span>
          </div>
        </div>
      </div>
      <div class="div-usage-guide-style" style="display: flex;">
        <div class="div-usage-guide-message-style">
          <svg width="50" height="25" viewBox="0, 0, 50, 30" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="50" height="25" rx="5" ry="5" fill="rgb(231, 76, 60)" />
          </svg>
        </div>
        <div class="div-usage-guide-message-style">
          <span class="ml-2" v-html="$t('message.message_vehicle_system_guide_not_conformed_front')"></span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
              stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h13M12 5l7 7-7 7" />
            </svg>
            <span>{{ $t('message.message_vehicle_system_guide_not_conformed_rear') }}</span>
          </div>
          <div>
            <span style="width: 50;">{{ $t('message.message_vehicle_system_guide_caution') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-7 text-right text-nowrap mt-2">
      <div>
        <b-button v-if="reprogramTableTypes.includes('update') && displayReproTypeMode === 'campaign'"
          variant="primary" v-on:click="showMerchantEcuList" size="sm">
          {{ $t('message.button_vehicle_system_show_distributor_ecu') }}</b-button>
        <span class="ml-3"></span>
        <b-button v-if="reprogramTableTypes.includes('restore') && displayReproTypeMode === 'campaign'"
          variant="primary" v-on:click="showRestoreEcuList" size="sm">
          {{ $t('message.button_vehicle_system_show_distributor_ecu') }}</b-button>
        <span class="ml-3"></span>
        <b-button variant="primary" size="sm" @click="openRxswinModal()">
          {{ $t('message.button_vehicle_system_show_vehicle_rxswin') }}</b-button>
        <span class="ml-3"></span>
        <b-button variant="primary" v-on:click="switchTable" v-show="!isPublished && isVisible" size="sm">
          {{ $t('message.button_vehicle_system_show_published') }}</b-button>
        <b-button variant="primary" v-on:click="switchTable" v-show="isPublished && isVisible" size="sm">
          {{ $t('message.button_vehicle_system_show_unpublished') }}</b-button>
      </div>
      <div v-if="isVisible" class="mt-2">
        <div v-if='isPublished'>
          <strong>{{ $t('message.label_vehicle_published') }}</strong>
        </div>
        <div v-else>
          <strong>{{ $t('message.label_vehicle_unpublished') }}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive text-left mt-1">
    <table class="table" id="su-system-table">
      <thead class="thead-dark">
        <tr>
          <th v-for="h in setupSuTableColumn()" :data-field="h.key" @click="sortCompare(h.key)">
            <span>{{ $t(h.label) }}</span>
            <span v-if="sortCol === h.key">{{ sortDir === 'desc' ? ' ▲' : ' ▼' }}</span>
          </th>
        </tr>
      </thead>
      <!-- 公開 -->
      <tbody v-show="isPublished">
        <tr v-for="(item, i) in publishedTableItems" :key="i">
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{ item.nameForDefaultTbl }}</td>
          <td v-if="displayReproTypeMode === 'update'" :class="item.bgStyle.other">{{ item.nameForUpdateTbl }}</td>
          <td v-if="displayReproTypeMode === 'restore'" :class="item.bgStyle.other">{{ item.nameForRestoreTbl }}</td>
          <td :class="item.bgStyle.hwid" class="tbl-line-break-style">{{ item.hwid }}</td>
          <td :class="setBgStyle(item, 'swid')" class="tbl-line-break-style">{{ item.swid }}</td>
          <td :class="setBgStyle(item, 'romid')" class="tbl-line-break-style">{{ item.targetId }}</td>
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.marketReproResource.label) }}</td>
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.otherReproResource.label) }}</td>
          <td v-if="displayReproTypeMode === 'update'" :class="item.bgStyle.other">{{ $t(item.updateReproResource.label)
            }}</td>
          <td v-if="displayReproTypeMode === 'restore'" :class="item.bgStyle.other">{{
            $t(item.restoreReproResource.label) }}</td>
          <td :class="item.bgStyle.other">
            <!-- 許諾応答が null または、法規不適合ならハイフン表示 -->
            <div v-if="isSwidConsent(item)" class="text-center">
              <label size="sm">{{ $t('message.common_hyphen') }}</label>
            </div>
            <div v-else class="text-center">
              <b-button variant="info" class="btn-style" size="sm" @click="openConsentModal(item, 'swids')">
                {{ $t(getSwidConsentLabel(item)) }}
              </b-button>
            </div>
          </td>
          <td :class="item.bgStyle.other">{{ $t(getReproExecutetableLabel(item)) }}</td>
          <td :class="item.bgStyle.other">
            <div v-if="isDetail(item)" class="text-center">
              <b-button variant="info" size="sm" class="btn-style" @click="showDetails(item)">
                {{ $t('message.common_button_details') }}
              </b-button>
            </div>
            <div v-else class="text-center">
              <label size="sm">{{ $t('message.common_hyphen') }}</label>
            </div>
          </td>
          <td :class="item.bgStyle.other">{{ $t(item.installationResource.label) }}</td>
          <td :class="item.bgStyle.other">{{ $t(item.communicableResource.label) }}</td>
        </tr>
      </tbody>
      <!-- 非公開 -->
      <tbody v-show="!isPublished && isVisible">
        <tr v-for="(item, i) in unpublishedTableItems" :key="i"
          >
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{ item.nameForDefaultTbl }}</td>
          <td v-if="displayReproTypeMode === 'update'" :class="item.bgStyle.other">{{ item.nameForUpdateTbl }}</td>
          <td v-if="displayReproTypeMode === 'restore'" :class="item.bgStyle.other">{{ item.nameForRestoreTbl }}</td>
          <td :class="item.bgStyle.hwid" class="tbl-line-break-style">{{ item.hwid }}</td>
          <td :class="setBgStyle(item, 'swid')" class="tbl-line-break-style">{{ item.swid }}</td>
          <td :class="setBgStyle(item, 'romid')" class="tbl-line-break-style">{{ item.targetId }}</td>
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.marketReproResource.label) }}</td>
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.otherReproResource.label) }}</td>
          <td v-if="displayReproTypeMode === 'update'" :class="item.bgStyle.other">{{ $t(item.updateReproResource.label)
            }}</td>
          <td v-if="displayReproTypeMode === 'restore'" :class="item.bgStyle.other">{{
            $t(item.restoreReproResource.label) }}</td>
          <td :class="item.bgStyle.other">
            <!-- 許諾応答が null または、法規不適合ならハイフン表示 -->
            <div v-if="isSwidConsent(item)" class="text-center">
              <label size="sm">{{ $t('message.common_hyphen') }}</label>
            </div>
            <div v-else class="text-center">
              <b-button variant="info" class="btn-style" size="sm" @click="openConsentModal(item, 'swids')">
                {{ $t(getSwidConsentLabel(item)) }}
              </b-button>
            </div>
          </td>
          <td :class="item.bgStyle.other">{{ $t(getReproExecutetableLabel(item)) }}</td>
          <td :class="item.bgStyle.other">
            <div v-if="isDetail(item)" class="text-center">
              <b-button variant="info" size="sm" class="btn-style" @click="showDetails(item)">
                {{ $t('message.common_button_details') }}
              </b-button>
            </div>
            <div v-else class="text-center">
              <label size="sm">{{ $t('message.common_hyphen') }}</label>
            </div>
          </td>
          <td :class="item.bgStyle.other">{{ $t(item.installationResource.label) }}</td>
          <td :class="item.bgStyle.other">{{ $t(item.communicableResource.label) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="displayReproTypeMode !== 'campaign'" class="text-right">
    <!-- 戻るボタン -->
    <b-button variant="outline-primary" v-on:click="back()" class="ml-2 mr-2 mt-4" size="sm">
      {{ $t('message.common_button_back') }}
    </b-button>
  </div>

  <!-- 許諾変更ダイアログ -->
  <b-modal v-model="showModalConsent" id="modal-consent" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop centered no-close-on-esc scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_system_swid_update_consent') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="row" :class="{ 'scroll-hide': processing }" style="font-weight: bold;">
        <div class="col-12">
          {{ $t("message.label_vehicle_system_current_swid_state") }}: "{{ $t(consentState.label) }}"
        </div>
        <div class="col-12 mt-2">
          {{ $t("message.label_vehicle_system_target_system") }}
          <div class="row" v-for="ecu in consentTable.ecus">
            <div class="col-5">
              <label class="pl-3">{{ ecu.name }}</label>
            </div>
            <div v-if="consentType === 'swids'" class="col-7">
              <label>{{ ecu.current }}<span>
                  <svg class="mb-1 mr-2 ml-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                    viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="M5 12h13M12 5l7 7-7 7" />
                  </svg></span>{{ ecu.latest }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-12" v-html="$t('message.message_vehicle_system_swid_update_consent_caution')"></div>
      </div>
      <b-overlay :show="processing" opacity="0.8" spinner-variant="primary" no-wrap no-fade></b-overlay>
    </template>

    <template v-slot:footer>
      <div class="text-center">
        <b-button size="sm" variant="outline-primary" @click="showModalConsent=false" class="ml-2 mr-2" :disabled="processing">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button v-if="consentState.order !== 0" size="sm" variant="primary" @click="updateConsentState('consented')"
          class="ml-2 mr-2" :disabled="processing">
          {{ $t("message.button_vehicle_system_consent") }}
        </b-button>
        <b-button v-if="consentState.order !== 1" size="sm" variant="primary" @click="updateConsentState('rejected')"
          class="ml-2 mr-2" :disabled="processing">
          {{ $t("message.button_vehicle_system_not_consent") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- RxSWIN表示ダイアログ -->
  <b-modal v-model="showModalRxswin" id="modal-rxswin" header-bg-variant="secondary" size="ms" header-text-variant="light" no-stacking
    no-close-on-backdrop centered no-close-on-esc scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_system_rxswin_of_vehicle') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="row" :class="{ 'scroll-hide': processing }" style="font-weight: bold;">
        <div class="div-rxswin-style text-left text-nowrap">
          <div v-for="rxswin in rxswins">
            {{ rxswin }}
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center">
        <b-button size="sm" variant="primary" @click="copyRxswin()" class="ml-2 mr-2" :disabled="processing">
          {{ $t('message.button_vehicle_system_copy_vehicle_rxswin_to_clipboard') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="showModalRxswin=false" class="ml-2 mr-2" :disabled="processing">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>