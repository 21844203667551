// 実行画面の router:name と navigationの文言 MAP
export const EXECUTE_NAVIGATION_RESOURCES = {
  home: 'message.header_home_menu',
  blank: '',
  systemTable: 'message.header_vehicle_system_table',
  systemInformation: 'message.header_vehicle_system_information',
  attention: 'message.header_vehicle_system_repro',
  execution: 'message.header_vehicle_system_repro',
  result: 'message.header_repro_result',
  suSystemTable: 'message.header_vehicle_system_table',
  usbVerificationFailed: 'message.header_vehicle_system_information',
  soldVehicleSystemTable: 'message.header_search_flash_write2',
  // TODO v300 => v230 merge マスク対応 ----------
  // menu: 'message.header_function_menu',
  // diagProjectList: 'message.header_measured_data',
  // dtcTable: 'message.header_vehicle_system_table',
  // diagProjectDetails: 'message.header_measured_data',
  // dataRecollection: 'message.header_measures_recollection'
  // --------------------------------------------
};