<div class="container">
  <div class="home-base-container">
    <div v-if="isTestMode" class="text-center sub-title-style mb-2" style="color: red;">
      {{ $t('message.label_title_test_mode') }}
    </div>
    <div class="row row-0">
      <div v-if="displayRepro" class="col mr-3 home-menu-container" v-bind:class="rprIconStyle"
        v-on:click="openReprogramming()">
        <strong style="font-size: x-large;" class="mt-3 home-menu-label">{{ $t("message.label_function_menu_reprogramming") }}</strong>
        <div class="text-center switch-icon-display">
          <div v-if="switchRepro.mainType !== 'communicationError'">
            <svg-icon :iconKey="switchRepro.menuIconKey"></svg-icon>
            <div class="ml-10 mr-10 rounded-pill d-flex align-items-center justify-content-center "
              :class="switchRepro.styleActive === 'activeRepro' ? 'possible' : 'no'">{{ $t(switchRepro.validRepro) }}
            </div>
            <div class="ml-10 mr-10 rounded-pill d-flex align-items-center justify-content-center "
              :class="switchRepro.styleActive === 'deactivateRepro' ? 'possible' : 'no'">{{ $t(switchRepro.invalidRepro) }}
            </div>
          </div>
          <div v-else>
            <svg-icon :iconKey="switchRepro.menuIconKey"></svg-icon>
            <div class="warning ml-10 mr-10 rounded-pill d-flex align-items-center justify-content-center">{{
              $t(switchRepro.warningRepro) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="displayAlldtc" class="col mr-3 home-menu-container" v-bind:class="dtcIconStyle"
        v-on:click="openDTCList()">
        <strong style="font-size: x-large;" class="mt-3 home-menu-label">{{ $t("message.label_function_menu_all_dtc") }}</strong>
        <div class="text-center switch-icon-display">
          <div v-if="switchDtc.mainType !== 'communicationError'">
            <svg-icon :iconKey="switchDtc.menuIconKey"></svg-icon>
            <div class="ml-10 mr-10 rounded-pill d-flex align-items-center justify-content-center "
              :class="switchDtc.styleActive === 'activeDtc' ? 'possible' : 'no'">{{ $t(switchDtc.validDtc) }}
            </div>
            <div class="ml-10 mr-10 rounded-pill d-flex align-items-center justify-content-center "
              :class="switchDtc.styleActive === 'deactivateDtc' ? 'possible' : 'no'">{{ $t(switchDtc.invalidDtc) }}
            </div>
          </div>
          <div v-else>
            <svg-icon :iconKey="switchDtc.menuIconKey"></svg-icon>
            <div class="warning ml-10 mr-10 rounded-pill d-flex align-items-center justify-content-center">{{
              $t(switchDtc.warningDtc) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col mr-3 home-menu-container unselectable"
        v-bind:class="{ 'selectable' : isServiceAdmin(), 'unselectable' : !isServiceAdmin() }"
        v-on:click="openImobiFunction()">
        <strong style="font-size: x-large;" class="mt-3 home-menu-label">{{ $t("message.label_function_menu_imobi_registration")
          }}</strong>
        <svg-icon iconKey="keyIcon"></svg-icon>
      </div>
      <div class="col mr-3 home-menu-container unselectable"
        v-bind:class="{ 'selectable' : isServiceAdmin(), 'unselectable' : !isServiceAdmin() }"
        v-on:click="openMacKeyRegistration()">
        <strong style="font-size: x-large;" class="mt-3 home-menu-label">{{ $t("message.label_function_menu_mac_key_registration")
          }}</strong>
        <svg-icon iconKey="macKeyIcon"></svg-icon>
      </div>
    </div>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>