// テストモードブラウザ復帰後システム名表示
export const testModeSystemNameDisplay = (testModePairingStatus, testDataList) => {
    // テストモードでブラウザ切断→復活の場合、API通信は実施しないのでキャッシュしてあるものを使用する
    const executeReproItemId = testDataList.filter(item => {
        for (let index = 0; index < item.system_names.length; ++index) {
            for (let sysIndex = 0; sysIndex < testModePairingStatus.systems.length; ++sysIndex) {
                if (item.system_names[index].system_name === testModePairingStatus.systems[sysIndex].name) {
                    return true;
                }
            }
        }
        return false;
    });
    return executeReproItemId[0].id;
};

export default {
    testModeSystemNameDisplay,
};