import * as apiService from "../share/util/api/service";
import * as apiResponse from "../share/util/api/response";
import * as apiErrorMapping from "../share/util/api/errorMapping";

/**
 * @deprecated 今後は share/util/api/* をインポートして利用すること
 */
export default {
  ...apiService.default,
  ...apiResponse.default,
  ...apiErrorMapping.default,
};