<div class="container-fluid mt-2 mb-2">
  <label style="font-weight: bold;">{{ $t('message.label_vehicle_ffd_select_measurement') }}</label>
  <b-form-select v-model="selectedFFDGroup" size="sm" @change="updateFFDTable" id="ffd_model"
    class="custom-select custom-select-sm">
    <option v-for="opt in ffdGroupOptions" :value="opt.key">{{ opt.value.label }}</option>
  </b-form-select>
  <div class="mt-2 mb-2" style="display: flex;">
    <div>
      <b-button variant="primary" size="sm" @click="initializeFFDSort()">
        {{ $t('message.button_vehicle_ffd_initialize_sort') }}
      </b-button>
    </div>
    <div class="ml-2">
      <slot name="didTestBtn"></slot>
    </div>
  </div>
  <cstm-table-modal ref="ffd_tbl" useSort initSortDir="asc" :items="ffdTableItems" :columns="ffdDetailTableColumns"
    type="simple" usePopup useStripeStyle hasTabs>
    <!-- ページングコンポーネントは呼び出し元から指定 -->
    <!-- <template v-slot:contentRightEnd>
      <cstm-paging-modal ref="ffd_pagination" :pageSize='maxItemsPerPage' v-bind:initPage=1
        v-bind:totalPageLength='totalFFDItemLength' @selecedPage="switchTablePage" inRprService />
    </template> -->
  </cstm-table-modal>
</div>