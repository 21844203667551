<template>
  <div class="mt-4">
    <cstm-progress
      :message="name"
      :value="progress"
      class="mt-4"
      ref="node"
    />
    <div class="mt-2 ml-1">
      <label class="text-left">{{
        $t("message.common_label_status")
      }}</label>
      <label
        v-if="onProgramming"
        class="text-left"
        style="font-weight: bold"
      >
        {{ $t('message.status_repro_execute_on_program') }}
      </label>
      <label
        v-else
        class="text-left"
        style="font-weight: bold"
      >
        {{ status }}
      </label>
      <span
        v-if="innerProgressIcon"
        class="ml-2"
      >
        <b-spinner
          small
          variant="primary"
        />
      </span>
    </div>
    <div class="mb-5 ml-1">
      <div v-if="remainingTime">
        <label
          class="text-left"
        >
          {{ $t("message.common_label_repro_remaining_time") }}
        </label>
        <label
          class="text-left"
          style="font-weight: bold"
        >
          {{ remainingTime }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import cstmProgress from '../../../share/components/vue/CustomProgress';

export default {
  props: {
    name: { type: String, default: '', required: false },
    progress: { type: Number, default: 0, required: false, },
    variant: { type: String, default: 'primary', required: false },
    max: { type: Number, default: 100, required: false },
    useResource: { type: Boolean, default: false, required: false },
    status: { type: String, default: "", required: false },
    onProgramming: { type: Boolean, default: false, required: false },
    remainingTime: { type: String, default: '', required: false },
    innerProgressIcon: { type: Boolean, default: true, required: false },
  },

  components: {
    'cstm-progress': cstmProgress
  },
};
</script>

<style>
</style>
