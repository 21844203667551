// i18n wrapper function of javascript . 
// i18nのラッパー関数
// i18nがnullになってしまうOSSの不具合を回避するためのwrapper

export default {
  install(Vue) {
    const _$t = Vue.config.globalProperties.$t;
    Vue.config.globalProperties._$t = _$t;

    Vue.config.globalProperties.$t = function () {
      if (this.$i18n) {
        if (arguments.length > 0 && !arguments[0]) {
          // 空のキーが指定された場合はデフォルトのメッセージを返す
          return arguments[0];
        } else {
          return _$t.apply(this, arguments);
        }
      } else {
        //console.log("$i18n is NULL");
        return _$t.apply(this.$root, arguments);
      }
    };
  }
};