export default {
  props: {
    normal: { type: Boolean, default: false },
    variant: { type: String, default: "" },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    iconKey: { type: String, default: "", required: true },
    disabled: { type: Boolean, default: false },
    class: { type: String, default: "" },
  },
  emits: ['onclick'],

  data() {
    return {
      nomalIconClassName: 'icon-size-normal',
      buttonClass: ""
    };
  },

  methods: {
    onclick(event) {
      if (!this.disabled) {
        this.$emit("onclick", event);
      }
    }
  },

  mounted() {
    if (this.size === 'sm') {
      this.nomalIconClassName = 'icon-size-sm';
    }
    else {
      this.nomalIconClassName = 'icon-size-normal';
    }
    if (!this.normal) {
      this.buttonClass = `${this.class} svg-button rounded-pill float-left`;
    }
    else {
      this.buttonClass = this.class;
    }
  }
};