import config from '../../../config';

export default {
  props: {
    inActive: { type: Boolean, default: false },
    items: { type: Array, default: null },
    columns: { type: Array, default: null },
    currentSortkey: { type: String, default: "" },
    rowSelect: { type: Boolean, default: false },
    useSort: { type: Boolean, default: false },
    initSortDir: { type: String, default: config.DESC },
    excludeSortVal: { type: String, default: null },
  },
  data() {
    return {
      currentSort: '',
      currentSortDir: this.initSortDir,
      propItems: null,
      columnLength: 0,
    };
  },

  watch: {
    // 初期生成時も監視
    items: {
      handler(value) {
        // 設定されたitem情報に変更があった場合には初期化処理を実施する。
        this.currentSort = this.currentSortkey;
        if (value && value.length <= 0) {
          this.propItems = null;
          this.displayCount = '-';
        }
        else {
          this.propItems = value.concat();
        }
      },
      immediate: true,
      deep: true
    },

    // 初期生成時も監視
    columns: {
      handler(val) {
        if (val && val.length <= 0) {
          this.columnLength = 0;
        }
        else {
          this.columnLength = val.length;
        }
      },
      immediate: true,
    },

  },

  methods: {
    sort(s) {
      if (s.name === this.currentSort) {
        this.currentSortDir = this.currentSortDir === config.ASC ? config.DESC : config.ASC;
      }
      this.currentSort = s.name;
    },
    getSortIcon() {
      if (this.currentSortDir === config.ASC) {
        return "<span> ▲</span>";
      } else {
        return "<span> ▼</span>";
      }
    },
    rowClicked(user) {
      this.$emit("rowClicked", user);
    },

    getColumnClasses(data, columnName) {
      if (!data.fieldSpec || !data.fieldSpec[columnName]) {
        return {};
      }

      const columnConfig = data.fieldSpec[columnName];
      return {
        'field-highlight': columnConfig === config.LIST_ITEM_HIGHLIGHT,
        'field-error': columnConfig === config.LIST_ITEM_ERROR,
        'field-bold': columnConfig === config.LIST_ITEM_BOLD || columnConfig === config.LIST_ITEM_HIGHLIGHT,
        'field-disable': columnConfig === config.LIST_ITEM_DISABLE
      };
    },

    scrollToTop() {
      this.$nextTick(() => {
        const chatLog = this.$refs.table_tbody;
        if (!chatLog) return;
        chatLog.scrollTop = 0;
      });
    }

  },

  /* eslint-disable complexity */
  computed: {
    sortedActivity() {
      let resultItems = null;
      if (this.propItems) {
        resultItems = this.propItems
          .sort((a, b) => {
            if (a[this.currentSort] !== a[this.currentSort] && b[this.currentSort] !== b[this.currentSort]) return 0;
            if (a[this.currentSort] !== a[this.currentSort]) return 1;
            if (b[this.currentSort] !== b[this.currentSort]) return -1;

            if (a[this.currentSort] === null && b[this.currentSort] === null) return 0;
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;

            if (a[this.currentSort] === '' && b[this.currentSort] === '') return 0;
            if (a[this.currentSort] === '') return 1;
            if (b[this.currentSort] === '') return -1;

            if (this.excludeSortVal) {
              if (a[this.currentSort] === this.excludeSortVal && b[this.currentSort] === this.excludeSortVal) return 0;
              if (a[this.currentSort] === this.excludeSortVal) return 1;
              if (b[this.currentSort] === this.excludeSortVal) return -1;
            }

            const sig = this.currentSortDir === config.DESC ? 1 : -1;
            return a[this.currentSort] < b[this.currentSort] ? sig : (a[this.currentSort] > b[this.currentSort] ? -sig : 0);
          });
      }

      return resultItems;
    }
  },
};