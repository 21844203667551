import api from '../../common/api';
import util from '../../share/util/utils';
import handler from '../../share/util/apiResultValidator';
import config from '../../share/config';
import resource from '../../share/util/resourceMap';
const publishedUnitListTestMode = require('../../data/testMode/publishedUnits.json');

export default {
  data() {
    return {

    };
  },
  namespaced: true,

  state: {
    // リプロ機能向けシステム一覧テーブル情報
    systemTable: null,
    // リプロ機能テーブルでのダイアログ表示制御用フラグ
    showSystemTblModal: true,
    // 診断情報一覧データ
    allDtcSystems: null,
  },

  mutations: {
    setNativeSystemTable(state, payload) {
      state.systemTable = payload;
    },
    setSystemTblModalFlag(state, payload) {
      state.showSystemTblModal = payload;
    },
    setAllDtcTable(state, payload) {
      state.allDtcSystems = payload;
    }
  },

  actions: {
    // DTCテーブル一覧情報を取得
    async getDtcSystemTable(context, arg) {
      // Response結果をオブジェクトに入れる
      const getDtcResponse = {
        errorResult: null
      };
      if (arg.projectId && arg.measureId) {
        const endPoint = '/diagDtc/' + arg.projectId + '/' + arg.measureId;
        const res = await api.getCall(config.REPRO, endPoint);
        handler.validate(
          handler.validateTypes.all,
          res, null, null,
          () => {
            if (!res.data.clear_dtc_all_system_count && res.data.clear_dtc_all_system_count !== 0) {
              // clear_dtc_all_system_countが無かった場合
              res.data.clear_dtc_all_system_count = -1;
            }
            // 処理が成功したときデータを入れる
            context.commit('setAllDtcTable', res.data);
          },
          (result) => {
            // 処理が失敗してる場合
            getDtcResponse.errorResult = result;
          }, null, false);
        return getDtcResponse;
      }
      // measureIdが取れ無かった場合、エラーにはせず空配列で返す
      else {
        context.commit('setAllDtcTable', { clear_dtc_all_system_count: -1, data: [] });
        return getDtcResponse;
      }

    },

    // テーブルを取得
    async setNativeSystemTable(context, arg) {
      if (arg.mode === 'testMode') {
        // Random select programmable status
        const publishedUnitListTestModeTemp = JSON.parse(JSON.stringify(publishedUnitListTestMode));
        for (let index = 0; index < publishedUnitListTestModeTemp.length; ++index) {
          if (Math.random() < 0.5) {
            publishedUnitListTestModeTemp[index].reprogrammable_info.force.reprogrammable_status = config.SYSTEM_STATUS_NOT_REPROGRAMMABLE;
            publishedUnitListTestModeTemp[index].pfc_file_id = null;
          }
          // Special handling for RCR to display target unit information
          if (publishedUnitListTestModeTemp[index].system_names.length > 1 &&
            publishedUnitListTestModeTemp[index].system_names[0].system_name === 'RCR_L') {
            const targetRomId = publishedUnitListTestModeTemp[index].target_romid;
            publishedUnitListTestModeTemp[index].target_romid = `LH：${targetRomId.slice(0, -1)}0  RH：${targetRomId.slice(0, -1)}2`;
          }
        }
        const publishResult = resource.getVehicleSystemStatus(publishedUnitListTestModeTemp, this.suFlow);
        for (let index = 0; index < publishResult.vehicleSystemList.length; ++index) {
          publishResult.vehicleSystemList[index].name = publishedUnitListTestModeTemp[index].name;
          publishResult.vehicleSystemList[index].fieldSpec.isCommunicatable = config.LIST_ITEM_DISABLE;
          publishResult.vehicleSystemList[index].fieldSpec.isInstallation = config.LIST_ITEM_DISABLE;
        }
        // テーブルをキャッシュ
        const tbl = {
          published: util.sortItems(publishResult.vehicleSystemList, 'id', config.ASC),
          unpublished: []
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
      else if (arg.mode === 'techMode') {
        // テーブルをキャッシュ
        const tbl = {
          published: [],
          unpublished: []
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
      else {
        if (!arg.netAppId) {
          return { isValid: false, data: null };
        }
        const pblEndpoint = '/netApps/' + arg.netAppId + '/vehicle/units/published';
        const unpblEndpoint = '/netApps/' + arg.netAppId + '/vehicle/units/unpublished';
        let requestType = 'ssm5';
        if (arg.isSoldVehicle) {
          requestType = 'sold_vehicle';
        }

        let pblTbl = [];
        let unpblTbl = [];
        let errObject = null;

        // 公開テーブル取得
        const pblRes = await api.getCall(config.REPRO, pblEndpoint + '/?request_type=' + requestType);
        const isValid = handler.validate(
          handler.validateTypes.all, pblRes, arg.obj, null, null, (result) => {
            errObject = result;
          }, null, false
        );
        if (!isValid || errObject) return { isValid: isValid, data: errObject };
        pblTbl = pblRes.data;

        // 非公開テーブル取得
        if (arg.isBothTable) {
          const unpblRes = await api.getCall(config.REPRO, unpblEndpoint);
          const isValid = handler.validate(
            handler.validateTypes.all, unpblRes, arg.obj, null, null, (result) => {
              errObject = result;
            }, null, false
          );
          if (!isValid || errObject) return { isValid: isValid, data: errObject };
          unpblTbl = unpblRes.data;
        }
        let tbl = {};
        // 既販車の場合
        if (arg.isSoldVehicle) {
          tbl = {
            published: util.sortItems(pblTbl.pack_file_list, 'create_at', config.ASC),
            unpublished: [],
            countryGroups: pblTbl.country_groups
          };
        } else {
          tbl = {
            published: util.sortItems(pblTbl, 'id', config.ASC),
            unpublished: util.sortItems(unpblTbl, 'id', config.ASC)
          };
        }
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
    },

    setSystemTblModalFlag(context, arg) {
      context.commit('setSystemTblModalFlag', arg);
    },

    // 情報を初期化
    clearTableData(context) {
      context.commit('setNativeSystemTable', null);
      context.commit('setSystemTblModalFlag', true);
      context.commit('setAllDtcTable', null);
    },
  }
};