// import util from '../../../share/util/utils';
import resource from '../../../share/util/resourceMap';
import util from '../../../share/util/utils';
// import cstmPaging from '../../../share/components/vue/Pagination';
import cstmTable from '../../../share/components/vue/PaginationTable';
import config from '../../../share/config';

const FFD_I18N_MAP = require('../../../data/ffdi18nKeyMap.json');

export default {
  name: 'ffdModal',
  data() {
    return {
      // ffdListBuff: [],
      ffdGroupOptions: [],
      selectedFFDGroup: null,
      ffdDetailTableColumns: [],
      ffdTableItems: [],
      maxItemsPerPage: Number(process.env.VUE_APP_PROJECT_PAGE_DISPLAY_COUNT),
      // totalFFDItemLength: 0,
      // currentPage: 0,
      currentFFDContents: {}
    };
  },

  props: {
    ffdTableProp: { type: Array, default: [], required: false },
    systemProp: { type: Object, default: {}, required: false },
  },

  components: {
    // 'cstm-paging-modal': cstmPaging,
    'cstm-table-modal': cstmTable,
  },

  methods: {

    // FFD グループの選択オプションを生成
    makeFFDGroupOptions: function () {
      if (!this.ffdTableProp || this.ffdTableProp.length < 1) return;
      // ffd_save_order で重複排除
      const confirmed = this.$t('message.status_vehicle_ffd_order_confirmed');
      const temporary = this.$t('message.status_vehicle_ffd_order_temporary');
      const saveOrder0Confirm = this.$t('message.status_vehicle_ffd_order_latest', { 0: confirmed });
      const saveOrder0Tentative = this.$t('message.status_vehicle_ffd_order_latest', { 0: temporary });
      const saveOrder1Confirm = this.$t('message.status_vehicle_ffd_order_past', { 0: confirmed });
      const saveOrder1Tentative = this.$t('message.status_vehicle_ffd_order_past', { 0: temporary });
      const flt = [];
      const opts = [];
      for (const p of this.ffdTableProp) {
        if (!flt.some(e => e.ffd_save_order === p.ffd_save_order && e.ffd_type === p.ffd_type)) {
          // 重複チェック用の配列データ
          flt.push({ ffd_save_order: p.ffd_save_order, ffd_type: p.ffd_type });
          // プルダウンのデータ生成
          const optData = { key: 0, value: { ffd_save_order: p.ffd_save_order, label: null, ffd_type: p.ffd_type } };
          if (p.ffd_save_order === 0 && p.ffd_type === config.FFD_TYPE_TENTATIVE) {
            optData.key = 1;
            optData.value.label = saveOrder0Tentative;
          }
          else if (p.ffd_save_order === 1 && p.ffd_type === config.FFD_TYPE_CONFIRM) {
            optData.key = 2;
            optData.value.label = saveOrder1Confirm;
          }
          else if (p.ffd_save_order === 1 && p.ffd_type === config.FFD_TYPE_TENTATIVE) {
            optData.key = 3;
            optData.value.label = saveOrder1Tentative;
          }
          else {
            // 上記条件に合致しない場合は「最新(確定)で表示」
            optData.value.label = saveOrder0Confirm;
          }
          opts.push(optData);
        }
      }
      // プルダウンの表示順のソート「最新(確定)⇒最新(仮)⇒過去(確定)⇒過去(仮)」
      const ffdResultItem = util.sortItems(opts, 'key', config.ASC);
      this.ffdGroupOptions = ffdResultItem;
      // プルダウン初期表示
      this.selectedFFDGroup = ffdResultItem[0].key;
    },

    // FFD 一覧の生成
    updateFFDTable: function (group) {
      const findIndex = this.ffdGroupOptions.findIndex(e => e.key === group);
      if (findIndex === -1) return;

      const ffdGroup = this.ffdGroupOptions[findIndex];
      // ffd_save_order が一致しているレコードを抽出
      const flt = this.ffdTableProp.filter(p => p.ffd_save_order === ffdGroup.value.ffd_save_order && p.ffd_type === ffdGroup.value.ffd_type);
      const instance = this;
      const pre = [
        { name: 'item', label: 'message.header_vehice_ffd_item', useResource: true },
        { name: 'unit', label: 'message.header_vehice_ffd_unit', useResource: true },
      ];
      const ffd = resource.getSaveOrderTable(flt, this.systemProp.systemID, FFD_I18N_MAP, instance);
      // 重複なしで column を作成
      const cols = Array.from(new Set(ffd.headers));
      const detected = cols.map(e => (e ? { name: e, label: '| ' + e, useHtmlBind: true } : { name: '0', label: 'message.header_vehice_ffd_detected' }));
      this.ffdDetailTableColumns = pre.concat(detected);
      // テーブルの内容を作成
      this.currentFFDContents = ffd;
      this.ffdTableItems = ffd.items;
      // this.ffdListBuff = ffd.items;
      // this.totalFFDItemLength = this.ffdListBuff.length;
      // this.currentPage = 1;
      // this.ffdTableItems = this.filterPerPageSize();
    },

    // FFD ソート初期化
    initializeFFDSort: function () {
      this.ffdTableItems.sort((a, b) => {
        if (a.monitorSort < b.monitorSort) return -1;
        if (a.monitorSort > b.monitorSort) return 1;
        return 0;
      });
      // this.ffdTableItems = this.filterPerPageSize();
    },

    // テストデータを生成して返す
    getTestData: function () {
      return this.currentFFDContents;
    },

    // テーブルページの切り替え
    // switchTablePage: function (page) {
    // 	this.$refs.ffd_tbl.scrollToTop();
    //   this.currentPage = page;
    //   this.ffdTableItems = this.filterPerPageSize();
    // },

    // 取得できている FFD 情報全体から、現在のページ表示するものだけをフィルタ
    // filterPerPageSize: function () {
    //   const ret = this.ffdListBuff.filter((row, index) => {
    //     const start = (this.currentPage - 1) * this.maxItemsPerPage;
    //     const end = this.currentPage * this.maxItemsPerPage;
    //     return index >= start && index < end;
    //   });
    //   return ret;
    // },
  },

  created() {
    // noop
  },

  mounted() {
    // FFD グループの選択オプションを生成
    this.makeFFDGroupOptions();
    this.updateFFDTable(this.selectedFFDGroup);
  },
};