import Error from '@/common/nodejs/errors';

export default {
  isSuccessResponse(response) {
    if (!response) {
      return false;
    }
    if (response.code > 0) {
      return false;
    }
    return true;
  },

  isClientConnectorNotAliveError(response) {
    if (!response) {
      return false;
    }
    /* eslint-disable-next-line */
    return response.code == Error.CLOUD_CONNECTOR_NOT_ACTIVE || response.code == Error.DB_PAIRING_INFO_NOT_FOUND ? true : false;
  },

  isUserAlreadyDeleted(response) {
    if (!response) {
      return false;
    }

    /* eslint-disable eqeqeq */
    return response.code == Error.USER_ALREADY_DELETED ||
      (typeof response.auth_code !== 'undefined' && response.auth_code == Error.USER_ALREADY_DELETED) ? true : false;
    /* eslint-enable */
  },

  isInvalidSessionToken(response) {
    if (!response) {
      return false;
    }

    /* eslint-disable eqeqeq */
    return response.code == Error.INVALID_SESSION_TOKEN ||
      (typeof response.auth_code !== 'undefined' && response.auth_code == Error.INVALID_SESSION_TOKEN) ? true : false;
    /* eslint-enable */
  },

  /**
   * サーバー側の設定エラーチェック
   * @param response: 応答レスポンス
   * @return {bool} error : true  other : false
  */
  isServerSideError(response) {
    if (!response) {
      return true;
    }
    const result = this.isServerSideErrorCode(response.code);
    return result;
  },

  /**
   * サーバー側の設定エラーチェック
   * @param response: エラーコード
   * @return {bool} error : true  other : false
  */
  isServerSideErrorCode(code) {
    if (!code) {
      return true;
    }
    // API Gateway で設定されているエラーコードは全てチェックする
    // 今後のために400系と500系のエラーは分けておく
    let result = false;
    switch (code) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 413:
      case 415:
      case 429:
      case 433:
        result = true;
        break;
      case 500:
      case 502:
      case 504:
        result = true;
        break;
    }
    return result;
  },

  /**
   * ネットワーク切断チェック
   * @param response: 応答レスポンス
   * @return {bool} error : true  other : false
  */
  isNetworkDisconnectError(response) {
    if (!response) {
      return false;
    }
    /* eslint-disable-next-line */
    return response.code == Error.FRONT_DISCONNECT_NETWORK ? true : false;
  },

  isPermissionError(response) {
    if (!response) {
      return false;
    }
    /* eslint-disable-next-line */
    return response.code == Error.PERMISSION_ERROR ? true : false;
  },

  isRunMaintenance(response) {
    if (!response) {
      return false;
    }
    /* eslint-disable eqeqeq */
    const result = response.code == Error.SYSTEM_UNDER_MAINTENANCE ||
      (typeof response.auth_code !== 'undefined' && response.auth_code == Error.SYSTEM_UNDER_MAINTENANCE) ? true : false;
    /* eslint-enable */
    return result;
  },
};