import api from '../../common/api';
import session from '../../share/session';
const Error = require('../../common/nodejs/errors');
import config from '../../share/config';
import handler from '../../share/util/apiResultValidator';
import util from '../../share/util/utils';

export default {
  namespaced: true,

  state: {
    // 必須領域
    status: null,
    error: null,
    watcher: null,
    usbWatcher: null,
    pairingAppId: null,
    isTestMode: false,
    usbStatus: null,
    usbError: null
  },

  mutations: {
    // 必須領域
    setStatus(state, payload) {
      state.error = payload.error;
      state.status = payload.status;
    },
    setWatcher(state, payload) {
      state.watcher = payload;
    },
    setUsbWatcher(state, payload) {
      state.usbWatcher = payload;
    },
    // 接続したクラウドコネクターのID
    setPairingAppId(state, payload) {
      if (payload) {
        state.pairingAppId = payload.netAppId;
        state.isTestMode = payload.isTestMode || false;
      } else {
        state.pairingAppId = null;
        state.isTestMode = null;
      }
    },
    setUsbStatus(state, payload) {
      state.usbError = payload.error;
      state.usbStatus = payload.status;
    },
  },

  actions: {

    /* **
    * クラウドコネクターとの pairing を実行する 
    * @param obj: this を指定
    * @param netAppId: 接続する netAppId 
    */
    async pairing(context, obj) {

      context.commit('setStatus', util.createResultInfo(config.POLLING_INITIALIZED));

      // Send pairing request.
      const pairingParam = obj.isNameType ? { name: obj.netAppId } : { id: obj.netAppId };
      const response = await api.postCall(config.REPRO, '/pairings', pairingParam);
      const isSuccess = handler.validate(
        handler.validateTypes.all,
        response, obj.this, null, null,
        (result) => {
          // 処理が失敗してる場合
          context.commit('setStatus',
            util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
        }, null, false);

      // 成功しない場合は処理を終了
      if (!isSuccess) {
        return;
      }

      // Get cloud connector id from the response
      const netAppId = response.data.id;

      // クラウドコネクターとのペアリング状態待ち 
      let currentRetry = 0;
      const retryMaxCount = Number(process.env.VUE_APP_RETRY_COUNT);

      const watcher = obj.this.$startInterval(async () => {
        try {
          const response = await api.getCall(config.REPRO, `/pairings/${netAppId}`);

          handler.validate(
            handler.validateTypes.all,
            response, obj.this, null,
            () => {
              try {
                // 処理が成功したときのコールバック処理
                const pairingStatus = response.data;

                // If the NetApp status is not active, no need to retry.
                if (pairingStatus.connect_status !== 'active') {
                  obj.this.$stopInterval(watcher);
                  context.commit('setStatus',
                    util.createResultInfo(config.POLLING_FAILURE,
                      obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_NOT_ACTIVE)),
                      handler.behaviorTypes.logout));
                  return;
                }
                // Check whether other users are using the NetApp.
                const sessionUser = session.getUser();
                if (pairingStatus.net_app_status !== 'none' &&
                  pairingStatus.net_app_status !== 'pairing_failed' &&
                  pairingStatus.user_id !== sessionUser) {
                  obj.this.$stopInterval(watcher);
                  context.commit('setStatus',
                    util.createResultInfo(config.POLLING_FAILURE,
                      obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_USEDBY_OTHER)),
                      handler.behaviorTypes.other));
                  return;
                }
                // Pairing success/failure with current user.
                if (pairingStatus.user_id === sessionUser) {
                  if (pairingStatus.net_app_status === 'pairing_failed') {
                    obj.this.$stopInterval(watcher);
                    context.commit('setStatus',
                      util.createResultInfo(config.POLLING_FAILURE,
                        obj.this.$t(api.getErrorMessageForCode(pairingStatus.err_code)),
                        handler.behaviorTypes.other));
                    return;
                  }

                  // ペアリング成功 
                  if (pairingStatus.net_app_status === 'pairing') {
                    obj.this.$stopInterval(watcher);
                    context.commit('setPairingAppId', { netAppId: netAppId, isTestMode: pairingStatus.is_test_mode });
                    context.commit('setStatus', util.createResultInfo(config.POLLING_COMPLETED));
                    return;
                  }
                }

                // リトライ上限超過
                if (++currentRetry === retryMaxCount) {
                  obj.this.$stopInterval(watcher);
                  context.commit('setStatus',
                    util.createResultInfo(config.POLLING_FAILURE,
                      obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                      handler.behaviorTypes.other));
                  return;
                }
              } catch (err) {
                // 予期しない例外が発生
                obj.this.$stopInterval(watcher);
                context.commit('setStatus',
                  util.createResultInfo(config.POLLING_FAILURE,
                    obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                    handler.behaviorTypes.other, err));
              }
            },
            (result) => {
              // 処理が失敗してる場合
              obj.this.$stopInterval(watcher);
              context.commit('setStatus',
                util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
            }, null, false);
        } catch (err) {
          // 予期しない例外が発生
          //console.log('Unexpected Error has occured @netAppConnector#updateVehicleInfo: ', err);
          obj.this.$stopInterval(watcher);
          context.commit('setStatus',
            util.createResultInfo(config.POLLING_FAILURE,
              obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
              handler.behaviorTypes.other, err));
        }
      }, Number(process.env.VUE_APP_RETRY_INTERVAL), "netAppConnector");

      // クラウドコネクターのIDを初期化 
      context.commit('setPairingAppId', null);
      // ポーリングの watcher ID を設定
      context.commit('setWatcher', watcher);
      // ポーリングの開始ステータスを設定
      context.commit('setStatus', util.createResultInfo(config.POLLING_RUNNING));
    },

    /* **
    * クラウドコネクターとの pairing を確認する  
    * @param netAppId: 接続する netAppId (※pairingしている場合のみ省略可能)
    */
    async getPairingStatus(context, payload) {

      const pairingResponse = {
        isConnected: false,
        response: null
      };

      let appId = payload.netAppId;
      if (!appId) {
        appId = context.state.pairingAppId;
      }
      if (!appId) {
        // クラウドコネクターとの接続が確立していない場合は不具合のため予期せぬエラーで返す
        pairingResponse.isConnected = false;
        pairingResponse.response = null;
        return pairingResponse;
      }
      try {
        const response = await api.getCall(config.REPRO, `/pairings/${appId}`);
        handler.validate(
          handler.validateTypes.all,
          response, payload.this, null,
          () => {
            // 処理が成功したときのコールバック処理
            pairingResponse.response = response;
            const pairingStatus = response.data;
            const sessionUser = session.getUser();
            // クラウドコネクターが接続状態かチェックする 
            if ((pairingStatus.user_id && pairingStatus.user_id.toLowerCase() === sessionUser.toLowerCase()) &&
              (pairingStatus.net_app_status !== 'none' &&
                pairingStatus.net_app_status !== 'pairing_failed')) {
              // クラウドコネクターが接続状態の場合は接続しているクラウドコネクターを設定する 
              context.commit('setPairingAppId', { netAppId: appId, isTestMode: pairingStatus.is_test_mode });
              pairingResponse.isConnected = true;
            }
          },
          // eslint-disable-next-line no-unused-vars
          (result) => {
            // 処理が失敗してる場合
            pairingResponse.isConnected = false;
            pairingResponse.response = response;
          }, null, false);
      } catch (error) {
        pairingResponse.isConnected = false;
        pairingResponse.response = null;
      }

      return pairingResponse;
    },

    // eslint-disable-next-line no-unused-vars
    async disconnectPairing(context, payload) {

      // リプロ実行中と完了画面時はペアリングを切断しない
      const phase = context.rootState.app.viewProcessMode;
      if (phase === config.PROCESS_REPRO_PROCESSING) {
        // CC切断時にリプロ実行中の場合はリプロ状態の旨を設定して返す  
        const result = { data: { result: config.PROCESS_REPRO_PROCESSING } };
        return result;
      }

      if (payload.isDeleteCache === true) {
        // 内部のキャッシュ情報は削除する。 
        context.dispatch('cache/cacheVehicleInfo', null, { root: true });
      }
      const appId = context.state.pairingAppId;
      if (!appId) {
        // クラウドコネクターとの接続が確立していない場合でも正常で返す
        const result = { data: { result: 'OK' } };
        return result;
      }
      try {
        const response = await api.deleteCall(config.REPRO, `/pairings/${appId}`);
        context.commit('setPairingAppId', null);
        return response;
      } catch (error) {
        return null;
      }
    },

    /* **
    * クラウドコネクターと pairing 中の車両に接続されているUSBの読み取り状況を確認する
    * @param netAppId: 接続する netAppId 
    */
    async updatePairingStorageInquiryStatus(context, obj) {
      context.commit('setUsbStatus', util.createResultInfo(config.POLLING_INITIALIZED));
      const response = await api.postCall(config.REPRO, `/netApps/${obj.netAppId}/storage`);
      const isSuccess = handler.validate(handler.validateTypes.all, response, obj.instance, null, null, (result) => {
        // 処理が失敗してる場合
        context.commit('setUsbStatus',
          util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
      }, null, false);
      // 成功しない場合は処理を終了
      if (!isSuccess) return;

      // クラウドコネクターとの接続が確立していない場合は処理を終了
      const appId = obj.netAppId || context.state.pairingAppId;
      if (!appId) return;

      // USB照合状態を監視
      let currentRetry = 0;
      const retryMaxCount = Number(process.env.VUE_APP_RETRY_COUNT);

      const watcher = obj.instance.$startInterval(async () => {
        try {
          const res = await api.getCall(config.REPRO, `/pairings/${appId}`);
          handler.validate(handler.validateTypes.all, res, obj.instance, null, () => {
            try {
              // 処理が成功したときのコールバック処理
              const pairingStatus = res.data;
              // If the NetApp status is not active, no need to retry.
              if (pairingStatus.connect_status !== 'active') {
                obj.instance.$stopInterval(watcher);
                context.commit('setUsbStatus', util.createResultInfo(
                  config.POLLING_FAILURE,
                  obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_NOT_ACTIVE)),
                  handler.behaviorTypes.logout));
                return;
              }

              // Check whether other users are using the NetApp.
              const sessionUser = session.getUser();
              if (pairingStatus.net_app_status !== 'none' && pairingStatus.net_app_status !== 'pairing_failed' && pairingStatus.user_id !== sessionUser) {
                obj.instance.$stopInterval(watcher);
                context.commit('setUsbStatus', util.createResultInfo(
                  config.POLLING_FAILURE,
                  obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_USEDBY_OTHER)),
                  handler.behaviorTypes.other));
                return;
              }
              // Pairing success/failure with current user.
              if (pairingStatus.user_id === sessionUser) {
                if (pairingStatus.net_app_status === 'pairing_failed') {
                  obj.instance.$stopInterval(watcher);
                  context.commit('setUsbStatus', util.createResultInfo(
                    config.POLLING_FAILURE,
                    obj.instance.$t(api.getErrorMessageForCode(pairingStatus.err_code)),
                    handler.behaviorTypes.other));
                  return;
                }

                // ペアリング成功 && 照合完了
                if (pairingStatus.net_app_status === 'pairing' && pairingStatus.net_app_storage_inquiry_status === 'completed') {
                  obj.instance.$stopInterval(watcher);
                  context.commit('setUsbStatus', util.createResultInfo(config.POLLING_COMPLETED));
                  return;
                }
              }

              // リトライ上限超過
              if (++currentRetry === retryMaxCount) {
                obj.instance.$stopInterval(watcher);
                context.commit('setUsbStatus', util.createResultInfo(
                  config.POLLING_FAILURE,
                  obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                  handler.behaviorTypes.other));
                return;
              }

            } catch (err) {
              // 予期しない例外が発生
              obj.this.$stopInterval(watcher);
              context.commit('setUsbStatus',
                util.createResultInfo(config.POLLING_FAILURE,
                  obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                  handler.behaviorTypes.other, err));
            }
          }, (result) => {
            // 処理が失敗したときのコールバック処理
            obj.instance.$stopInterval(watcher);
            context.commit('setUsbStatus', util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
          }, null, false);
        } catch (err) {
          obj.instance.$stopInterval(watcher);
          context.commit('setUsbStatus', util.createResultInfo(
            config.POLLING_FAILURE,
            obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
            handler.behaviorTypes.other, err));
        }
      }, Number(process.env.VUE_APP_RETRY_INTERVAL), "netAppConnector");

      // ポーリングの watcher ID を設定
      context.commit('setUsbWatcher', watcher);
      // ポーリングの開始ステータスを設定
      context.commit('setUsbStatus', util.createResultInfo(config.POLLING_RUNNING));
    },
  }
};

