import config from '../config';

// 車両番号を２つに分解して返却する
// chassisNumber : 車両番号 xxxxx-xxxxxxx
export const chassisNumberSplit = (chassisNumber) => {
    const result = ['', ''];
    if (chassisNumber) {
        const arr = chassisNumber.split(config.VEHICLEINFO_VEHICLENUMBER_DELIMITER);
        if (arr && arr.length !== 0) {
            result[0] = arr[0];
            result[1] = arr[1];
        }
    }
    return result;
};

// リプロ対象の対象ユニット情報を作成する
// info : vehicle 情報
// targetUnits : 対象ユニット一覧情報
// region : 仕向け地情報
// recovery : 直接リプロフェーズに復帰するかどうか
export const createTargetUnitInfo = (info, recovery) => {

    const result = {
        unitId: info.vehicle.unit_id,
        fileId: info.vehicle.pfc_id,
        publishmentMode: true,
        directRecoveryToRpr: recovery,
        paneConfig: {}
    };
    
    return result;
};

// 登録・変更された車種情報を、キャッシュ用のオブジェクトに変更する。
// 登録・変更された情報のツリー階層を、次回の一覧表示時に開いた状態で表示するため。
// code: 開発コード
// region: 仕向け地
// vehicleModel: 車種
// classification: 型式
// annualImprovements: 区分
// yearModel: 年式
// annualClassification: 年改区分
export const createLatestInfo = (code, region, vehicleModel, classification, annualImprovements, yearModel, annualClassification) => {

    const result = {
        code: code,
        region: region,
        vehicleModel: vehicleModel,
        annualClassification: annualClassification === '' ? config.VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE : annualClassification
    };
    if (region === config.REGION_DOMESTIC)
        result.typeModel = classification + ' ' + annualImprovements;
    else
        result.typeModel = yearModel;

    return result;
};

// Get target unit display names for the language
export const getTargetUnitDisplayNames = (targetUnitList, language) => {
    const targetUnitDisplayData = {};
    targetUnitList.forEach(item => {
        const filteredList = item.display_names.filter(displayName => displayName.language === language);
        targetUnitDisplayData[item.id] = filteredList.length === 0 ? '' : filteredList[0].name;
    });
    return targetUnitDisplayData;
};

// Get vehicle model display names for the language
export const getVehicleModelDisplayNames = (vehicleModelList, language) => {
    const vehicleModelListDisplayData = {};
    vehicleModelList.forEach(item => {
        const filteredList = item.display_names.filter(displayName => displayName.language === language);
        vehicleModelListDisplayData[item.id] = filteredList.length === 0 ? '' : filteredList[0].name;
    });
    return vehicleModelListDisplayData;
};

// 車両情報取得ステータスからメッセージIDを取得する
// state : 車両情報取得時のステータス情報
// beforeMessageId : 前回設定時のメッセージID
export const getPhaseResources = (state, beforeMessageId) => {
    let result = null;
    switch (state) {
        case config.COMMUNICATION_UI_VIN_REQUIRED:
            result = 'message.header_vin_input_title';
            break;
        case config.COMMUNICATION_UI_VEHICLE_CGW:
            result = 'message.label_vehicle_connect_cgw';
            break;
        case config.COMMUNICATION_UI_VEHICLE_OBD:
        case config.COMMUNICATION_UI_VIN_COMPLETED:
            result = 'message.label_vehicle_connect_obd';
            break;
        case config.COMMUNICATION_UI_VEHICLE_SYSTEMS:
            // TODO ph3をmergeする際はこちらのコードを優先する
            result = 'message.label_vehicle_connect_update_rxswin';
            if (beforeMessageId !== 'message.label_vehicle_connect_update_rxswin') {
                result = 'message.label_vehicle_connect_systems';
            }
            break;
        case config.COMMUNICATION_UI_RXSWIN_UPDATED:
            result = 'message.label_vehicle_connect_update_rxswin';
            break;
        case config.COMMUNICATION_UI_GET_DIAG_CODE:
            result = 'message.label_vehicle_connect_diag_code';
            break;
        case config.COMMUNICATION_UI_COMMUNICATION_COMPLETED:
            result = 'message.label_vehicle_connect_completed';
            break;
        default:
            result = 'message.label_vehicle_connect_completed';
            break;
    }
    return result;
};

// Get vehicle model display names for the language
export const getVehicleSystemMode = (isTestMode, isTechUser) => {
    let result = 'nomalMode';
    if (isTestMode) {
        result = 'testMode';
        return result;
    }
    if (isTechUser) {
        result = 'techMode';
        return result;
    }
    return result;
};

// regionが日本か判定
export const isDomestic = (region) => {
    return region === config.REGION_DOMESTIC;
};

export default {
    chassisNumberSplit,
    createTargetUnitInfo,
    createLatestInfo,
    getTargetUnitDisplayNames,
    getVehicleModelDisplayNames,
    getPhaseResources,
    getVehicleSystemMode,
    isDomestic
};