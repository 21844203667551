import Dialog from './Dialog.vue';

const Plugin = {
  install(app, options = {}) { // eslint-disable-line no-unused-vars
    if (this.installed) {
      return;
    }

    this.installed = true;
    this.event = app;
    this.rootInstance = null;

    app.component('BvDialog', Dialog);

    const showStaticModal = (params) => {
      const data = { state: true, params: params };
      Plugin.event.config.globalProperties.$bus.emit('toggle', data);
    };

    app.config.globalProperties.$modalDialog = {
      show(modal, ...args) {
        return showStaticModal(...args);
      },
      hide() {
        const data = { state: false, params: null };
        Plugin.event.config.globalProperties.$bus.emit('toggle', data);
      },
      // 使用していないのでコメント
      // toggle(name, params) {
      //   this.$emit('toggle', name, undefined, params);
      // }
    };
  }
};

export default Plugin;