let variantType = 'primary';

/* **
 * 指定されたvariantの確認ダイアログを表示する
 * @param type: 'primary' か 'secondary' を指定
 */
export const setVariantType = (type) => {
  variantType = type;
};

/* **
 * 指定されたパラメータでエラーのメッセージを表示する。
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param message: ダイアログの内部文言を指定
 * @param okButtonHandler: OKボタン押下時の関数を指定 ※省略可能
 * @param okButtonTitle: OKボタンタイトル名を指定 ※省略可能
 */
export const showError = (modal, title, message, okButtonHandler = null, okButtonTitle = null) => {
  if (!modal) return;
  let str = title;
  if (!title) {
    str = modal.$t('message.common_title_error');
  }

  showMessage(modal, 'danger', str, message, true, okButtonHandler, okButtonTitle);
};

/* **
 * 指定されたパラメータでエラーのメッセージを表示する。
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param message: ダイアログの内部文言を指定(配列指定)
 * let messages = { mainMessage : value, messageList : []];
    let message1 = {
      label : "label1",
      message : message
    }
 * @param okButtonHandler: OKボタン押下時の関数を指定 ※省略可能
 * @param okButtonTitle: OKボタンタイトル名を指定 ※省略可能
 */
export const showErrorMsgObject = (modal, title, messages, okButtonHandler = null, okButtonTitle = null) => {
  if (!modal) return;
  let str = title;
  if (!title) {
    str = modal.$t('message.common_title_error');
  }
  showMessage(modal, 'danger', str, messages, true, okButtonHandler, okButtonTitle);
};

/* **
 * 指定されたパラメータで警告のメッセージを表示する。
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param message: ダイアログの内部文言を指定
 * @param okButtonHandler: OKボタン押下時の関数を指定 ※省略可能
 * @param okButtonTitle: OKボタンタイトル名を指定 ※省略可能
 */
export const showWarning = (modal, title, message, okButtonHandler = null, okButtonTitle = null) => {
  if (!modal) return;
  let str = title;
  if (!title) {
    str = modal.$t('message.common_title_warning');
  }
  showMessage(modal, 'warning', str, message, true, okButtonHandler, okButtonTitle);
};

/* **
 * 指定されたパラメータで情報のメッセージを表示する。
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param message: ダイアログの内部文言を指定
 * @param okButtonHandler: OKボタン押下時の関数を指定 ※省略可能
 * @param okButtonTitle: OKボタンタイトル名を指定 ※省略可能
 */
export const showInfo = (modal, title, message, okButtonHandler = null, okButtonTitle = null) => {
  if (!modal) return;
  let str = title;
  if (!title) {
    str = modal.$t('message.common_title_infomation');
  }
  showMessage(modal, 'info', str, message, true, okButtonHandler, okButtonTitle);
};

/* **
 * 指定されたパラメータで成功のメッセージを表示する。
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param message: ダイアログの内部文言を指定
 * @param okButtonHandler: OKボタン押下時の関数を指定 ※省略可能
 * @param okButtonTitle: OKボタンタイトル名を指定 ※省略可能
 */
export const showSuccess = (modal, title, message, okButtonHandler = null, okButtonTitle = null) => {
  if (!modal) return;
  let str = title;
  if (!title) {
    str = modal.$t('message.common_title_success');
  }
  showMessage(modal, 'success', str, message, true, okButtonHandler, okButtonTitle);
};

/* **
 * 指定されたパラメータで情報の変更・削除の確認メッセージを表示する。
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param message: ダイアログの内部文言を指定
 * @param okButtonHandler: OKボタン押下時の関数を指定 ※省略可能
 * @param okButtonTitle: OKボタンタイトル名を指定 ※省略可能
 * @param cancelButtonTitle: キュンセルボタンタイトル名を指定 ※省略可能
 */
export const showConfirmation = (modal, title, message, okButtonHandler = null, okButtonTitle = null, cancelButtonTitle = null) => {
  if (!modal) return;
  let str = title;
  if (!title) {
    str = modal.$t('message.common_title_confirmation');
  }
  showMessage(modal, variantType, str, message, false, okButtonHandler, okButtonTitle, cancelButtonTitle);
};

export const showConfirmationWithComponent = (modal, title, component, params) => {
  const htmlTitle = `<div class="model-confirm-dialog">${title}</div>`;
  modal.show('dialog', {
    title: htmlTitle,
    component,
    props: params,
    buttons: []
  });
};

function showMessage(modal, variant, title, message, okOnly, okButtonHandler = null, okButtonTitle = null, cancelButtonTitle = null) {

  let okTitle;
  if (!okButtonTitle) {
    okTitle = modal.$t('message.common_button_ok');
  }

  let cancelTitle;
  if (!cancelButtonTitle) {
    cancelTitle = modal.$t('message.common_button_cancel');
  }

  let showMessage = 'No set message';
  if (message) {
    if (typeof message === "object") {
      showMessage = message;
    }
    else {
      showMessage = message.split('\n').join('<br/>');
    }
  }

  modal.$modalDialog.show(modal.$bvModal,
    {
      variant,
      title,
      text: showMessage,
      okOnly,
      ok: () => {
        if (okButtonHandler) {
          okButtonHandler();
        }
      },
      okButtonText: okTitle,
      cancelButtonText: cancelTitle
    }
  );
}

export default {
  setVariantType,
  showError,
  showWarning,
  showInfo,
  showSuccess,
  showConfirmation,
  showConfirmationWithComponent
};