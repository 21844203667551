<div class="container-fluid">
	<div class="row">
		<div class="col-12 mt-1">
			<cstm-card headerTitle="message.header_measured_data_serch" :isCollapse="isSearchFieldOpened"
				accordionId="1">
				<template v-slot:body>
					<div class="row">
						<div class="col-12 d-flex mt-2">
							<div class="d-flex w-100">
								<div class="w-13 mr-2">
									<b-form-group class="form-group">
										<label>{{ $t('message.label_measured_data_search_registered_date') }}</label>
										<b-form-select v-model="selectedYear" :options="searchYearOptions" size="sm"
											:disabled="filledProjectId !== ''"
											:state="alertObject.registeredAt.valid" class="custom-select custom-select-sm"/>
										<b-form-invalid-feedback>{{ $t(alertObject.registeredAt.msg) }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="w-25 mr-2">
									<b-form-group class="form-group">
										<label>{{ $t('message.label_measured_data_search_registrants') }}</label>
										<b-form-input type="text" v-model="filledUser" size="sm"
											:disabled="filledProjectId !== ''" :state="alertObject.registeredBy.valid">
											{{ filledUser }}</b-form-input>
										<b-form-invalid-feedback>{{ $t(alertObject.registeredBy.msg) }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="w-25 mr-2">
									<b-form-group class="form-group">
										<label>{{ $t('message.label_measured_data_search_vin') }}</label>
										<b-form-input type="text" v-model="filledVin" size="sm"
											:disabled="filledProjectId !== ''" :state="alertObject.vin.valid">
											{{ filledVin }}</b-form-input>
										<b-form-invalid-feedback>{{ $t(alertObject.vin.msg) }}</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="w-12 text-center top-bottom-centering mr-2">
									<strong>{{ $t('message.label_or') }}</strong></div>
								<div class="w-25">
									<b-form-group class="form-group">
										<label>{{ $t('message.label_measured_data_search_project_id') }}</label>
										<b-form-input type="text" v-model="filledProjectId" size="sm"
											:disabled="filledProjectIdDisabled" :state="alertObject.projectId.valid">
											{{ filledProjectId }}</b-form-input>
										<b-form-invalid-feedback>{{ $t(alertObject.projectId.msg) }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
							</div>
						</div>
						<div class="col-12 text-center mt-n1 mb-3">
							<svg-button variant="primary" normal size="sm" iconKey="search" title="common_button_search"
								@onclick="searchProject()" />
						</div>
					</div>
				</template>
			</cstm-card>
		</div>
        <div class="col-12 mt-1">
            <div class="project-tbl-style">
                <div class="row width-max">
                    <div class="col-12 header-buttom-line">
                        <label class="header-title-style mt-3">{{ $t('message.header_measured_data_list') }}</label>
                    </div>
                    <div class="col-12 d-flex mt-2">
                        <div class="d-flex w-100">
                            <div class="w-25 mr-2">
                                <b-form-group class="form-group">
                                    <label>{{ $t('message.label_measured_data_filter_belongs') }}</label>
                                    <b-form-select v-model="selectedBelongs" :options="filterBelongsOptions" size="sm"
                                        @change="updateFilter" class="custom-select custom-select-sm">
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="w-15 mr-2">
                                <b-form-group class="form-group">
                                    <label>{{ $t('message.label_measured_data_filter_vehicle_name') }}</label>
                                    <b-form-select v-model="selectedVehicle" :options="filterVehicleOptions" size="sm"
                                        @change="updateFilter" class="custom-select custom-select-sm">
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="w-12 mr-2">
                                <b-form-group class="form-group">
                                    <label>{{ $t('message.label_measured_data_filter_classification') }}</label>
                                    <b-form-select v-model="selectedClass" :options="filterClassOptions" size="sm" :disabled="filterClassdDisabled"
                                        @change="updateFilter" class="custom-select custom-select-sm">
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="w-12 mr-2">
                                <b-form-group class="form-group">
                                    <label>{{ $t('message.label_measured_data_filter_my') }}</label>
                                    <b-form-select v-model="selectedMy" :options="filterMyOptions" size="sm" :disabled="filterMyDisabled"
                                        @change="updateFilter" class="custom-select custom-select-sm">
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="w-36 mr-2">
                                <b-form-group class="form-group">
                                    <label>{{ $t('message.label_measured_data_filter_diagnosis_comment') }}</label>
                                    <b-form-input type="text" v-model="filledComment" size="sm" @change="updateFilter">
                                    </b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-1 mb-3">
                        <b-button variant="outline-primary" size="sm" @click="clearFilterCondition()">{{
                            $t('message.common_button_clear') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
		<div class="col-12 mt-1">
			<div class="project-tbl-style">
				<div class="row width-max">
					<div class="col-12 mt-3">
						<div class="d-flex align-items-center">
							<cstm-paging class="mb-1" ref="project_paging" :pageSize='maxItemsPerPage' :initPage=1
								:totalPageLength='totalProjectsLength' @selecedPage="switchTablePage" inRprService />
							<b-spinner small variant="primary" v-if="runPagingProcess" />
						</div>
					</div>
					<div class="col-12 mt-2 width-max">
						<cstm-tbl type="simple" :columns="projectTableColumns" :items="projectsTable" useSort
							currentSortkey="id" @rowClicked="goToProjectDetail" ref="project_tbl" class="width-max"
							useStripeStyle rowSelect hasTabs/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<processing-view :processing="processing"></processing-view>
</div>