/* eslint-disable */

let idToken = null;
let loginToken = null;
let user = null;
let sessionTimer = null;
let sessionCallback = null;
let sessionTimeout = 0;
let sessionTimerStartTime = null;
let sessionTimerPauseTime = null;
let remainingTimeInterval = 0;
let sessionTimerPaused = false;

export const getToken = () => {
  return idToken;
};

export const saveToken = token => {
  idToken = token;
};

export const destroyToken = () => {
  idToken = null;
};

export const getLoginToken = () => {
  return loginToken;
};

export const saveLoginToken = token => {
  loginToken = token;
};

export const destroyLoginToken = () => {
  loginToken = null;
};

export const getUser = () => {
  return user;
};

export const saveUser = username => {
  user = username;
};

// export const getRequestToken = (username) => {
//   const key = `${username.toLowerCase()}_request_token`;
//   var match = document.cookie.match(new RegExp('(^| )' + key + '=([^;]+)'));
//   if (match) return match[2];
//   return null;
// };

export const getRequestToken = (username) => {
  const key = `${username.toLowerCase()}_request_token`;
  let cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[key] || null;
};

export const saveRequestToken = (username, requestToken) => {
  var date = new Date();
  date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60 * 1000)); // 10 Years
  document.cookie = `${username.toLowerCase()}_request_token=${requestToken};expires=${date.toUTCString()};path=/;secure`;
};

export const removeRequestToken = (username) => {
  var date = new Date();
  date.setTime(date.getTime() + (-10 * 365 * 24 * 60 * 60 * 1000));
  document.cookie = `${username.toLowerCase()}_request_token=;expires=${date.toUTCString()};path=/;secure`;
};

export const destroyUser = () => {
  user = null;
};

export const setSessionTimerConfig = (callbackFun, timeout) => {
  sessionCallback = callbackFun;
  sessionTimeout = timeout;
}

export const setSessionTimeOut = (timeout) => {
  sessionTimeout = timeout;
}

export const startSessionTimer = () => {
  try {
    if (sessionTimer) {
      // console.log("startSessionTimer Session timer started!");
      clearTimeout(sessionTimer);
    }

    remainingTimeInterval = sessionTimeout;
    sessionTimerStartTime = new Date();
    sessionTimer = setTimeout(() => {
      stopSessionTimer();
      if (sessionCallback) {
        sessionCallback();
      }
    }, sessionTimeout);
    // console.log(`Session timer started! ID : ${sessionTimer} sessionTimeout : ${sessionTimeout}`);
  } catch (error) {
  }
}

export const stopSessionTimer = () => {
  try {
    //console.log("Session timer stopped!");
    if (sessionTimer) {
      // console.log(`Session timer stopped! ID : ${sessionTimer}`);
      clearTimeout(sessionTimer);
      sessionTimer = null;
    }
  } catch (error) {
  }
}

export const restartSessionTimer = () => {
  // console.log("Session timer restarted!");
  if (sessionTimer) {
    stopSessionTimer();
    startSessionTimer();
  }
}

export const pauseSessionTimer = () => {
  //console.log("Session timer paused!");
  try {
    if (sessionTimer) {
      // console.log(`Session timer pause SessionTimer ! ID : ${sessionTimer}`);
      clearTimeout(sessionTimer);
      sessionTimer = null;
    }
  } catch (error) {
  }
  sessionTimerPauseTime = new Date();
  remainingTimeInterval -= (sessionTimerPauseTime - sessionTimerStartTime);
  sessionTimerPaused = true;
}

export const resumeSessionTimer = () => {
  // Pause されていない場合は再開もしない
  if (!sessionTimerPaused) {
    // console.log(`Session pause flag is already ON. interval: ${remainingTimeInterval}`);
    return;
  }
  sessionTimerPaused = false;
  // console.log("Session timer resumed!");
  if (sessionTimerPauseTime == null) {
    startSessionTimer();
    return;
  }

  remainingTimeInterval -= (new Date() - sessionTimerPauseTime);
  // console.log(`Remaining interval: ${remainingTimeInterval}`)
  remainingTimeInterval = Math.max(0, remainingTimeInterval);
  if (remainingTimeInterval <= 0) {
    if (sessionCallback) {
      sessionCallback();
    }
    return;
  }

  sessionTimerStartTime = new Date();
  sessionTimer = setTimeout(() => {
    stopSessionTimer();
    if (sessionCallback) {
      sessionCallback();
    }
  }, remainingTimeInterval);
  // console.log(`Session timer started! ID : ${sessionTimer} sessionTimeout : ${remainingTimeInterval}`);
}


export const terminateSession = () => {
  stopSessionTimer();
  user = null;
  sessionTimer = null;
  sessionCallback = null;
  sessionTimeout = 0;
  sessionTimerStartTime = null;
  sessionTimerPauseTime = null;
  remainingTimeInterval = 0;
  sessionTimerPaused = false;
}

export default {
  getToken,
  saveToken,
  destroyToken,
  getLoginToken,
  saveLoginToken,
  destroyLoginToken,
  getUser,
  saveUser,
  destroyUser,
  getRequestToken,
  saveRequestToken,
  removeRequestToken,
  setSessionTimerConfig,
  setSessionTimeOut,
  startSessionTimer,
  stopSessionTimer,
  restartSessionTimer,
  pauseSessionTimer,
  resumeSessionTimer,
  terminateSession
};
