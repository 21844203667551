<!-- 通常ボタン -->
<b-button v-if="normal" :class="buttonClass" :variant="variant" :size="size" @click="onclick($event)" :disabled="disabled">
  <!-- ここから通常ボタンのSVGアイコンを追記する -->
  <!-- 検索アイコン iconKey : 'search' -->
  <svg v-if="(iconKey == 'search')" :class="nomalIconClassName" viewBox="0 0 24 24">
    <path
      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      class="icon-color" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>

  <!-- 追加アイコン iconKey : 'add' -->
  <svg v-if="(iconKey == 'add')" :class="nomalIconClassName" viewBox="0 0 16 16">
    <g class="icon-color" >
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </g>
  </svg>

  <!-- 更新アイコン iconKey : 'update' -->
  <svg v-if="(iconKey == 'update')" :class="nomalIconClassName" viewBox="0 0 24 24">
    <path
      d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z"
      class="icon-color" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>

  <!-- アップロードアイコン iconKey : 'upload' -->
  <svg v-if="(iconKey == 'upload')" :class="nomalIconClassName" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64">
    <g>
      <path
        d="M23,24c0.885,0,1.681-0.384,2.23-0.993l0,0L29,18.818V37c0,1.657,1.343,3,3,3s3-1.343,3-3l0,0V18.818l3.77,4.189l0,0
          C39.318,23.617,40.115,24,41,24c1.657,0,3-1.343,3-3c0-0.772-0.292-1.475-0.77-2.007l0,0l-9-10l0,0C33.682,8.384,32.885,8,32,8
          s-1.681,0.384-2.23,0.993l0,0l-9,10l0,0C20.292,19.525,20,20.228,20,21C20,22.657,21.343,24,23,24z M55,35c-1.657,0-3,1.343-3,3
          v12H12V38c0-1.657-1.343-3-3-3s-3,1.343-3,3v15c0,1.657,1.343,3,3,3h46c1.657,0,3-1.343,3-3V38C58,36.343,56.657,35,55,35z"
        class="icon-color" />
    </g>
  </svg>

  <!-- ダウンロードアイコン iconKey : 'download' -->
  <svg v-if="(iconKey == 'download')" :class="nomalIconClassName" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64">
    <g>
      <path d="M20.77,29.007l9,10l0,0C30.319,39.616,31.115,40,32,40s1.68-0.384,2.23-0.993l0,0l9-10l0,0
          C43.708,28.475,44,27.772,44,27c0-1.657-1.343-3-3-3c-0.885,0-1.682,0.383-2.23,0.993l0,0L35,29.182V11c0-1.657-1.343-3-3-3
          s-3,1.343-3,3v18.182l-3.77-4.189l0,0C24.681,24.384,23.885,24,23,24c-1.657,0-3,1.343-3,3C20,27.772,20.292,28.475,20.77,29.007
          L20.77,29.007z M55,35c-1.657,0-3,1.343-3,3v12H12V38c0-1.657-1.343-3-3-3s-3,1.343-3,3v15c0,1.657,1.343,3,3,3h22.997
          c0.001,0,0.002,0,0.003,0s0.002,0,0.003,0H55c1.657,0,3-1.343,3-3V38C58,36.343,56.657,35,55,35z"
          class="icon-color" />
    </g>
  </svg>

  <!-- infomation iconKey : 'info' -->
  <svg v-if="(iconKey == 'info')" viewBox="0 0 512 512" :class="nomalIconClassName">
    <g>
      <path d="M255.992,0.008C114.626,0.008,0,114.626,0,256s114.626,255.992,255.992,255.992
        C397.391,511.992,512,397.375,512,256S397.391,0.008,255.992,0.008z M300.942,373.528c-10.355,11.492-16.29,18.322-27.467,29.007
        c-16.918,16.177-36.128,20.484-51.063,4.516c-21.467-22.959,1.048-92.804,1.597-95.449c4.032-18.564,12.08-55.667,12.08-55.667
        s-17.387,10.644-27.709,14.419c-7.613,2.782-16.225-0.871-18.354-8.234c-1.984-6.822-0.404-11.161,3.774-15.822
        c10.354-11.484,16.289-18.314,27.467-28.999c16.934-16.185,36.128-20.483,51.063-4.524c21.467,22.959,5.628,60.732,0.064,87.497
        c-0.548,2.653-13.742,63.627-13.742,63.627s17.387-10.645,27.709-14.427c7.628-2.774,16.241,0.887,18.37,8.242
        C306.716,364.537,305.12,368.875,300.942,373.528z M273.169,176.123c-23.886,2.096-44.934-15.564-47.031-39.467
        c-2.08-23.878,15.58-44.934,39.467-47.014c23.87-2.097,44.934,15.58,47.015,39.458
        C314.716,152.979,297.039,174.043,273.169,176.123z" class="icon-color">
      </path>
    </g>
  </svg>

  <!-- ここまで SVGアイコンを追記する -->
  {{$t("message." + title)}}
</b-button>

<!-- 特殊ボタン -->
<b-button v-else :size="size" :class="buttonClass" @click="onclick($event)"
  :disabled="disabled">
  <span class="circle-div no-shadow float-left" v-bind:class="{ 'circle-disabled': disabled }"
    :disabled="disabled">
    <!-- ここから特殊ボタンのSVGアイコンを追記する -->

    <!-- ××SVGアイコン iconKey : 'create' -->
    <svg v-if="(iconKey == 'create')" class="icon-size" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14.79 14.79"
      style="enable-background:new 0 0 14.79 14.79;">
      <g>
        <path d="M14.79,9.349c0,0.387-0.313,0.7-0.699,0.7h-3.946v4.043c0,0.385-0.313,0.698-0.7,0.698H5.404
		c-0.386,0-0.7-0.313-0.7-0.698v-4.043H0.699C0.312,10.049,0,9.736,0,9.349V5.307c0-0.386,0.313-0.7,0.699-0.7h4.005V0.699
		C4.704,0.312,5.017,0,5.404,0h4.041c0.387,0,0.7,0.313,0.7,0.699v3.909h3.945c0.386,0,0.699,0.314,0.699,0.7
		C14.789,5.308,14.789,9.349,14.79,9.349z" class="icon-color" />
      </g>
    </svg>

    <!-- ××SVGアイコン iconKey : 'remove' -->
    <svg v-else-if="(iconKey == 'initPassword')" class="icon-size" viewBox="0 0 512 512" fill="currentColor">
      <path d="M359.022,330.022l1.123-1.121c7.252-7.252,7.252-19.01,0-26.264l-15.471-15.473
  c-7.252-7.252-19.01-7.252-26.26,0l-1.123,1.125l-59.498-59.5c40.217-51.453,36.701-126.207-10.625-173.527
  C196,4.09,112.743,4.09,61.575,55.262L38.376,78.459c-51.168,51.168-51.168,134.424,0.002,185.592
  c47.324,47.326,122.08,50.846,173.529,10.629l59.498,59.494l-1.123,1.125c-7.252,7.248-7.252,19.01,0,26.264l15.471,15.469
  c7.25,7.252,19.008,7.252,26.26,0l1.123-1.125l113.152,113.152c8.078,8.078,21.172,8.078,29.25,0l16.637-16.639
  c8.078-8.072,8.078-21.17,0.002-29.25L359.022,330.022z M182.526,222.609c-28.316,28.316-74.39,28.316-102.707,0
  s-28.318-74.392-0.002-102.709l23.199-23.199c28.316-28.316,74.394-28.316,102.711,0s28.314,74.392-0.002,102.709L182.526,222.609z
  " class="icon-color" />
      <path d="M511.881,375.758l0.119-0.121l-30.039-14.754c-16.215-7.963-29.328-21.078-37.289-37.289l-11.106-22.613
  l-3.535-7.316l-0.039,0.039l-0.074-0.152l-45.172,45.168l82.086,82.078l34.078-34.07l11.019-10.945L511.881,375.758z"
        class="icon-color" />
    </svg>

    <!-- ××SVGアイコン iconKey : 'initPassword' -->
    <svg v-else-if="(iconKey == 'remove')" class="icon-size" viewBox="0 0 512 512" fill="currentColor">
      <path d="M77.869,448.93c0,13.312,1.623,25.652,5.275,35.961c4.951,13.636,13.475,23.457,26.299,26.297
  c2.598,0.488,5.277,0.812,8.117,0.812h277.364c0.73,0,1.381,0,1.947-0.082c26.463-1.703,37.258-29.219,37.258-62.988
  l11.121-269.324H66.748L77.869,448.93z M331.529,239.672h52.68v212.262h-52.68V239.672z M229.658,239.672h52.682v212.262h-52.682
  V239.672z M127.789,239.672h52.762v212.262h-52.762V239.672z" class="icon-color" />
      <path d="M368.666,89.289c0.078-2.028,0.242-4.059,0.242-6.09v-5.598c0-42.777-34.822-77.602-77.6-77.602h-70.701
  c-42.778,0-77.6,34.824-77.6,77.602v5.598c0,2.031,0.162,4.062,0.326,6.09H28.721v62.582h454.558V89.289H368.666z M320.205,83.199
  c0,2.113-0.242,4.141-0.648,6.09H192.361c-0.406-1.949-0.65-3.977-0.65-6.09v-5.598c0-15.91,12.986-28.898,28.897-28.898h70.701
  c15.99,0,28.896,12.988,28.896,28.898V83.199z" class="icon-color" />
    </svg>

    <!-- ここまで SVGアイコンを追記する -->
  </span>
  <span class="title-label">{{$t("message." + title)}}</span>
</b-button>