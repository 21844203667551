export default {
  props: {
    disabled: false,
    navigationItems: {
      type: Array,
      required: false,
      default: null
    }
  },

  methods: {
    isInternalLink(path) {
      return !/^https?:\/\//.test(path);
    },
    onClick(breadCrumb) {
      // navigationが無効かどうかを確認 
      if (!this.disabled) {
        if (this.isInternalLink(breadCrumb.path)) {
          const routerData = {
            name: breadCrumb.name
          };
          // params データが存在するかチェック
          if (breadCrumb.param) {
            routerData['params'] = breadCrumb.param;
          }
          this.$router.push(routerData);
        } else {
          location.href = breadCrumb.path;
        }
      }
    }
  }
};