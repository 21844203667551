export default {
  props: {
    id: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: "",
      required: false
    },
    useResource: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
    };
  }
};