const message_de = require('@/i18n/de.json');
const message_en = require('@/i18n/en.json');
const message_es = require('@/i18n/es.json');
const message_fr = require('@/i18n/fr.json');
const message_it = require('@/i18n/it.json');
const message_ja = require('@/i18n/ja.json');
const message_ru = require('@/i18n/ru.json');
const message_zh = require('@/i18n/zh.json');
const action_de = require('@/i18n/repro/action-de.json');
const action_en = require('@/i18n/repro/action-en.json');
const action_es = require('@/i18n/repro/action-es.json');
const action_fr = require('@/i18n/repro/action-fr.json');
const action_it = require('@/i18n/repro/action-it.json');
const action_ja = require('@/i18n/repro/action-ja.json');
const action_ru = require('@/i18n/repro/action-ru.json');
const action_zh = require('@/i18n/repro/action-zh.json');
const error_en = require('@/common/i18n/en.json');
const error_ja = require('@/common/i18n/ja.json');
const error_zh = require('@/common/i18n/zh.json');
const error_it = require('@/common/i18n/it.json');
const error_es = require('@/common/i18n/es.json');
const error_fr = require('@/common/i18n/fr.json');
const error_de = require('@/common/i18n/de.json');
const error_ru = require('@/common/i18n/ru.json');
export default Object.freeze({
  messages: {
    'en':{ message: message_en, action: action_en, error: error_en,},
    'ja':{ message: message_ja, action: action_ja, error: error_ja,},
    'zh':{ message: message_zh, action: action_zh, error: error_zh,},
    'it':{ message: message_it, action: action_it, error: error_it,},
    'es':{ message: message_es, action: action_es, error: error_es,},
    'fr':{ message: message_fr, action: action_fr, error: error_fr,},
    'de':{ message: message_de, action: action_de, error: error_de,},
    'ru':{ message: message_ru, action: action_ru, error: error_ru,},
  }
});

