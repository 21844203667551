export default {
  name: "tree-item",
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({})
    },
    level: {
      type: Number,
      required: true
    },
    showHeadElements: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isOpen: false,
      isHover: false,
      margin: '',
      extraMargin: '',
      isSelected: false,
      itemClassName: 'item-style-none',
    };
  },
  computed: {

    isLast() {
      return !(this.item.children && this.item.children.length);
    },

    isloading() {
      let result = false;
      if (!this.isLast && this.isOpen) {
        result = this.item.loading;
      }
      return result;
    }
  },

  watch: {
    'item.itemStyle': {
      immediate: true,
      handler(value) {
        if (value) {
          if (value === 'dark') {
            this.itemClassName = 'item-style-dark';
          }
        }
      }
    },
  },

  methods: {

    computeMargin() {
      if (Object.prototype.hasOwnProperty.call(this.item, "leftMargin")) {
        if (this.item.hasExtraColumn) {
          // 追加列あり
          this.margin = `margin-left: ${this.item.leftMargin}`;
          this.extraMargin = `margin-left: ${this.item.extraColumns.margin}`;
        } else {
          // 追加列なし
          this.margin = `margin-left: ${this.item.leftMargin}`;
        }
      }
      else {
        this.margin = `margin-left: ${this.level * 25}px;`;
      }
    },

    expanded(event) {
      if (!this.isLast) {
        this.isOpen = !this.isOpen;
        const expandedData = { data: this.item, isOpen: this.isOpen };

        this.$emit("expanded-item", expandedData);
        event.stopPropagation();
      }
    },

    nodeSelected(event) {
      if (this.item.noSelected) {
        // 選択が無効で設定されている項目は選択イベントを通知しない 
        return;
      }
      const selectData = {
        data: this.item,
        level: this.level
      };
      this.$emit("node-Selected", selectData);
      event.stopPropagation();
    },

    mouseEnterAndlLeave(event, isEnter) {
      let hover = isEnter;
      if (this.item.noSelected) {
        // 選択が無効で設定されている項目は選択イベントを通知しない 
        hover = false;
      }
      this.isHover = hover;
      event.stopPropagation();
    },
  },

  mounted() {
    // デフォルトで表示状態にするツリー階層があるかを確認
    if (this.item) {
      if (this.item.isOpen) {
        this.isOpen = true;
        // デフォルトでツリー階層を表示状態にする場合は expand イベントを発行しない
        // this.$emit("expanded-item", { data: this.item, isOpen: true });
      }
    }

    if (!this.showHeadElements) {
      this.isOpen = true;
    }
    this.computeMargin();

    // 初期に選択状態の場合は選択中のイベントを実行する 
    if (this.item.isSelected) {
      const selectData = {
        data: this.item,
        level: this.level
      };
      this.$emit("node-Selected", selectData);
    }
  }

};