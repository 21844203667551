import config from '../config';
import utils from './utils';

// コード独自ソート
export const sortFuncCode = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    let pCodeList = [];
    let cCodeList = [];
    let bCodeList = [];
    let uCodeList = [];
    const codeHyphenList = [];
    param.data.forEach(element => {
        if (element.dtc.indexOf('P') === 0) {
            pCodeList.push(element);
        }
        if (element.dtc.indexOf('C') === 0) {
            cCodeList.push(element);
        }
        if (element.dtc.indexOf('B') === 0) {
            bCodeList.push(element);
        }
        if (element.dtc.indexOf('U') === 0) {
            uCodeList.push(element);
        }
        if (element.description.indexOf('message.common_hyphen') > -1) {
            codeHyphenList.push(element);
        }
    });
    pCodeList = utils.sortItems(pCodeList, 'dtc', param.sortDir);
    cCodeList = utils.sortItems(cCodeList, 'dtc', param.sortDir);
    bCodeList = utils.sortItems(bCodeList, 'dtc', param.sortDir);
    uCodeList = utils.sortItems(uCodeList, 'dtc', param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = resultItems.concat(pCodeList);
        resultItems = resultItems.concat(cCodeList);
        resultItems = resultItems.concat(bCodeList);
        resultItems = resultItems.concat(uCodeList);
        resultItems = resultItems.concat(codeHyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = resultItems.concat(codeHyphenList);
        resultItems = resultItems.concat(uCodeList);
        resultItems = resultItems.concat(bCodeList);
        resultItems = resultItems.concat(cCodeList);
        resultItems = resultItems.concat(pCodeList);
        return resultItems;
    }
};

// FFD 独自ソート
export const sortFuncFfd = (param) => {
    if (!param.data) {
        return null;
    }
    const resultItems = utils.sortItems(param.data, 'buttonVisible', param.sortDir);
    return resultItems;
};

// 故障・診断独自ソート
export const sortFuncDescription = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    // 指定外コードリスト
    const unspecifiedList = [];
    // hyphenリスト
    const hyphenList = [];
    // DTC割り当て有りリスト
    let dtcDescriptionLists = [];
    param.data.forEach(element => {
        if (element.description.indexOf('message.status_vehicle_dtc_description_unspecified') > -1) {
            unspecifiedList.push(element);
        }
        if (element.description.indexOf('message.common_hyphen') > -1) {
            hyphenList.push(element);
        }
        if (element.description.indexOf('dtc.') > -1) {
            dtcDescriptionLists.push(element);
        }
    });
    dtcDescriptionLists = utils.sortItems(dtcDescriptionLists, 'description', param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = dtcDescriptionLists.concat(unspecifiedList);
        resultItems = resultItems.concat(hyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = hyphenList.concat(unspecifiedList);
        resultItems = resultItems.concat(dtcDescriptionLists);
        return resultItems;
    }
};

// IGON回数 独自ソート
export const sortIgon = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    let igonList = [];
    const hyphenList = [];
    param.data.forEach(element => {
        if (element[param.sortKey] !== '-') {
            igonList.push(element);
        }
        else{
            hyphenList.push(element);
        }
    });
    igonList = utils.sortItems(igonList, param.sortKey, param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = resultItems.concat(igonList);
        resultItems = resultItems.concat(hyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = resultItems.concat(hyphenList);
        resultItems = resultItems.concat(igonList);
        return resultItems;
    }
};


export default {
    sortFuncCode,
    sortFuncFfd,
    sortFuncDescription,
    sortIgon
};