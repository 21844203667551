<b-card no-body v-bind:class="{ 'card-no-border' : noBorder }">
  <b-card-header header-tag="header" role="tab" class="p-1" v-bind:class="{ 'card-header-gray' : isGray }">
    <div class="container-fluid">
      <div class="row row-20 card-header-buttom-line">
        <div v-if="!noHeader" class="col-auto">
          <div v-on:click="changeSearchHeaderIcon()">
            <svg v-if="isOpen"  viewBox="6 6 12 12"  class="icon-size">
              <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" 
              v-bind:class="{ 'icon-color' : isNomal, 'icon-gray-color' : isGray }"/>
            </svg>
            <svg v-else viewBox="6 6 12 12" class="icon-size">
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" 
              v-bind:class="{ 'icon-color' : isNomal, 'icon-gray-color' : isGray }"/>
            </svg>
            <span v-bind:class="{ 'header-title-style' : isNomal, 'header-title-gray-style' : isGray }">
              {{$t(headerTitle)}}
            </span>
          </div>
        </div>
        <div class="col">
          <slot name="header"></slot>
        </div>
      </div>
    </div>
  </b-card-header>
  <b-collapse v-model="isOpen" :accordion="accordionId" role="tabpanel">
    <b-card-body>
      <slot name="body"></slot>
    </b-card-body>
  </b-collapse>
</b-card>
