// State class for master resource.
/* eslint-disable max-lines */

import api from '@/common/api';
import config from '../config';
import util from '@/share/util/utils';
import handler from '../util/apiResultValidator';

// ダミー用の成功レスポンス
const successResponse = { status: 200, code: 0, data: {} };

export default {
  namespaced: true,

  state: {
    countries: [],
    targetUnits: [],
    engines: [],
    aspirations: [],
    transmissions: [],
    reproFactors: [],
    vehicleModels: [],
    vehicleModelRegionList: [],
    vehicleModelDisplayNameList: [],
    canidModels: [],
    canids: [],
    unitFilterList: []
  },

  mutations: {

    setResourceCountries(state, payload) {
      // 言語を判断して表示名を取得する関数を追加
      state.countries = [];
      payload.forEach((item) => {
        item.getName = function (lang) {
          if (lang === 'ja') {
            return item.japanese_name;
          } else {
            return item.english_name;
          }
        };
        state.countries.push(item);
      });
    },

    setTargetUnits(state, payload) {
      state.targetUnits = payload;
    },

    setEngines(state, payload) {
      state.engines = payload;
      // payloadがnullの場合、配列として初期化する
      if (state.engines === null) {
        state.engines = [];
      }
    },

    setAspirations(state, payload) {
      state.aspirations = payload;
      // payloadがnullの場合、配列として初期化する
      if (state.aspirations === null) {
        state.aspirations = [];
      }
    },

    setTransmissions(state, payload) {
      state.transmissions = payload;
      // payloadがnullの場合、配列として初期化する
      if (state.transmissions === null) {
        state.transmissions = [];
      }
    },

    setReproFactors(state, payload) {
      state.reproFactors = payload;
      // payloadがnullの場合、配列として初期化する
      if (state.reproFactors === null) {
        state.reproFactors = [];
      }
    },

    setVehicleModels(state, payload) {

      state.vehicleModels = payload;
      const vehicleData = state.vehicleModels;

      state.vehicleModelRegionList = [];
      // fileResourcesからリージョン情報を取得
      const regions = this.state.fileResources.groups;
      if (regions) {
        regions.forEach((item) => {
          // リージョン毎の車種情報を設定する
          const domestic = getRegionVehicleModel(vehicleData, item.value);
          state.vehicleModelRegionList.push(domestic);
        });
      }
    },

    setVehicleModelDisplayNames(state, payload) {
      state.vehicleModelDisplayNameList = payload || [];
    },

    setCanidModels(state, payload) {
      state.canidModels = payload || [];
    },

    setCanids(state, payload) {
      state.canids = payload || [];
    },

    setUnitFilterList(state, payload) {
      state.unitFilterList = payload;
      // payloadがnullの場合、配列として初期化する
      if (state.unitFilterList === null) {
        state.unitFilterList = [];
      }
    },

    // 車種情報から取得した、unitfiletr情報を保持
    setFromVehicleInfo(state, payload) {
      state.vehicleUnitFilterInfo = payload;
    },

    // 搭載・リプロ対象から取得した、unitfiletr情報を保持
    setReproUnitFilterInfo(state, payload) {
      state.reprpUnitFilterInfo = payload;
    },

    setUpdateUnitInfo(state, payload) {
      state.updateUnitInfo = payload;
    },

  },

  actions: {

    async getALLMasterResources(context, reload = false) {

      await Promise.all([
        context.dispatch('getCountries', reload),
        context.dispatch('getTargetUnits', reload),
        context.dispatch('getEngines', reload),
        context.dispatch('getAspirations', reload),
        context.dispatch('getTransmissions', reload),
        context.dispatch('getReproFactors', reload),
        context.dispatch('getVehicleModelDisplayNames', reload),
        context.dispatch('getVehicleModels', reload),
        context.dispatch('getUnitFilterList', reload),
      ]);
    },

    async getCountries(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.countries.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        // 国情報取得
        const response = await api.getParamCall(config.ADMIN, '/countries');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setResourceCountries', response.data.countries);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setResourceCountries', []);
          }, null, false);
        return response;
      }
      return successResponse;
    },

    // 対象ユニットのマスタ情報取得
    async getTargetUnits(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.targetUnits.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/targetUnits');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setTargetUnits', response.data.target_units);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setTargetUnits', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // エンジンのマスタ情報取得
    async getEngines(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.engines.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/engines');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setEngines', response.data.engines);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setEngines', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // 吸気方式のマスタ情報取得
    async getAspirations(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.aspirations.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/aspirations');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setAspirations', response.data.aspirations);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setAspirations', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // T_Mのマスタ情報取得
    async getTransmissions(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.transmissions.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/transmissions');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setTransmissions', response.data.transmissions);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setTransmissions', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // リプロ要因のマスタ情報取得
    async getReproFactors(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.reproFactors.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/reproFactors');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setReproFactors', response.data.repro_factors);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setReproFactors', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // Acquisition of master information of vehicle type display names information
    async getVehicleModelDisplayNames(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.vehicleModelDisplayNameList.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/vehicleModels/displayNames');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            // decodeにする処理
            const models = response.data.vehicle_models_displays;
            const VehicleModel = [];
            for (let index = 0; index < models.length; index++) {
              VehicleModel.push({
                id: decodeURI(models[index].id),
                display_names: models[index].display_names,
                last_update_time: models[index].last_update_time
              });
            }
            context.commit('setVehicleModelDisplayNames', VehicleModel);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setVehicleModelDisplayNames', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },


    // 車種情報のマスタ情報取得
    async getVehicleModels(context, reload = false) {
      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.vehicleModels.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/vehicleModels');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setVehicleModels', response.data.vehicle_models);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setVehicleModels', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // CANID情報のマスタ情報取得
    async getCanidModels(context, reload = false) {
      const update = reload || context.state.canidModels.length <= 0;

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.MASTER, '/canids');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setCanidModels', response.data.canid_models);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setCanidModels', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // CANIDの一覧取得
    async getCanids(context, reload = false) {
      const update = reload || context.state.canids.length <= 0;

      // リソース情報の取得
      if (update) {
        const response = await api.getParamCall(config.REPRO, '/canids');

        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setCanids', response.data.canids);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setCanids', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    async getUnitFilterList(context, reload = false) {
      // リソース情報の取得
      if (reload || context.state.unitFilterList.length <= 0) {
        const response = await api.getParamCall(config.MASTER, '/ecuHandling');
        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setUnitFilterList', response.data.ecu_handlings);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            context.commit('setUnitFilterList', []);
          }
          , null, false);
        return response;
      }
      return successResponse;
    },

    // 車種情報unitFilter
    setFromVehicleInfo(context, payload) {
      context.commit('setFromVehicleInfo', payload);
    },

    // 搭載・リプロ情報からのunitFilter
    setReproUnitFilterInfo(context, payload) {
      context.commit('setReproUnitFilterInfo', payload);
    },

    // ユニット情報画面で更新した情報を保持
    setUpdateUnitInfo(context, payload) {
      context.commit('setUpdateUnitInfo', payload);
    },
  }
};

const getRegionVehicleModel = (vehicleData, region) => {

  let result = null;

  const vehicleDetails = [];
  const domesticResults = [];
  util.deepSearch(vehicleData, 'name', (key, val) => val === region, domesticResults);

  const vehiclesResults = [];
  util.deepSearch(domesticResults, 'type', (key, val) => val === config.VEHICLEINFO_TYPE_VEHICLEMODEL, vehiclesResults);
  // 同じ車種情報は除外する
  const vehicles = [];
  vehiclesResults.forEach((vehicle) => {
    if (vehicles.indexOf(vehicle.name) === -1)
      vehicles.push(vehicle.name);
  });

  vehicles.forEach((name) => {
    const detailSet = {
      vehicleModel: name,
      modelInfoList: []
    };
    // リージョン x 車種でデータセット生成
    domesticResults.forEach((el) => {
      el.children.forEach((child) => {
        if (child.name === name) {
          child.children.forEach((grandchild) => {
            detailSet.modelInfoList.push(grandchild.name);
          });
        }
      });
    });
    vehicleDetails.push(detailSet);
  });

  result = {
    region,
    data: vehicleDetails
  };

  return result;
};