import utils from './utils';

// リプロ実行状態を返却する 
// reproStatus : リプロ実行状態 
export const isRunningReprogram = (reproStatus) => {
    let result = false;
    if (reproStatus === 'failure' ||
        reproStatus === 'executing' ||
        reproStatus === 'executing_ig_off' ||
        reproStatus === 'executing_ig_on') {
        result = true;
    }
    return result;
};

// リプロの状態監視ポーリングが実行中かどうかを返却する
export const isRunningReproPolling = (reproStatus) => {
    let result = false;
    if (reproStatus === 'executing' ||
        reproStatus === 'executing_ig_off' ||
        reproStatus === 'executing_ig_on') {
        result = true;
    }
    return result;
};

// 以下の処理は現在使用していないが、今後使用する可能性があるのでコメントアウト
// function getDefinedKeyFromReproState(code) {
//     let sDefinedKey = '';
//     switch (code) {
//         case 'none':
//         case 'power_on':
//         case 'target_confirmation':
//         case 'car_status':
//         case 'extended_session2':
//         case 'extended_session':
//         case 'gw_authentication':
//         case 'diagnosis_stop':
//         case 'cycle_stop':
//         case 'gw_mode_change':
//         case 'communication_check':
//         case 'security_access':
//         case 'unit_status':
//         case 'repro_session':
//             sDefinedKey = 'status_repro_execute_pre_program';
//             break;

//         case 'rom_clear':
//         case 'data_download':
//         case 'data_transfer':
//         case 'data_send_complete':
//         case 'check_sum':
//             sDefinedKey = 'status_repro_execute_on_program';
//             break;

//         case 'ig_offon':
//         case 'power_on_after_repro':
//         case 'fingerprint':
//         case 'rewrite_confirmation':
//         case 'extended_session3':
//         case 'gw_authentication2':
//         case 'memory_clear':
//         case 'memory_clear2':
//         case 'repro_complete':
//             sDefinedKey = 'status_repro_execute_post_program';
//             break;
//         default:
//             //console.log(code);
//             break;
//     }

//     return sDefinedKey;
// }

export const calculateFileDownloadTime = (totalDownloadTime, initvalue = null) => {
    let result = initvalue;
    if (totalDownloadTime) {
        result = utils.timeSpanConverter(totalDownloadTime);
    }
    return result;
};

export const toFixedDownloadSpeed = (downloadSpeed, initvalue = null) => {
    let result = initvalue;
    if (downloadSpeed) {
        const tmp = Number(downloadSpeed.toFixed(2));
        result = Number.isNaN(tmp) ? initvalue : tmp;
    }
    return result;
};

export default {
    isRunningReprogram,
    isRunningReproPolling,
    calculateFileDownloadTime,
    toFixedDownloadSpeed
};